import ApplicationSerializer from './application';

export default class MembershipSerializer extends ApplicationSerializer {
  getHashForResource() {
    let [hash, addToIncludes] = super.getHashForResource(...arguments);

    if (Array.isArray(hash)) {
      for (let membership of hash) {
        this._adjust(membership);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  }

  _adjust(kyc) {
    delete kyc.redirectionUri;
    delete kyc.id;
  }
}
