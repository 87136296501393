import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  accountType: 'iban',
  amount: 1000,
  amountCurrency: 'EUR',
  emitterBic: 'CRESCHZZXXX',
  emitterIban: 'FR7630004029990001013084373',
  emitterIdentification: 'ID12345',
  emitterName: 'John Doe',
  isIBANAccount: true,
  reference: 'Foobar',
  sequentialId: i => i + 1,

  afterCreate(income) {
    assert(income.bankAccount, `Missing 'bankAccount' relationship on 'income:${income.id}'`);

    assert(
      !income.organization && !income.organizationId,
      `You should not pass an 'organization' relationship on 'income:${income.id}'`
    );

    if (!income.slug) {
      let emitterName = income.emitterName.replaceAll(' ', '-').toLowerCase();
      let slug = `${income.bankAccount.organization.slug}-${emitterName}-income-${income.sequentialId}`;
      income.update({ slug });
    }
  },
});
