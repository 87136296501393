import { Factory } from 'miragejs';

import { sign } from '@qonto/mirage/utils/-re-jwt';
import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  documentsToUpload: true,
  birthdate: null,
  role: 'employee',
  status: 'pending',

  afterCreate(invite, server) {
    let {
      birthdate,
      email,
      firstName,
      lastName,
      organization,
      phoneNumber,
      recipient,
      recipientId,
      role,
      team,
      allowedBankAccountsIds,
    } = invite;

    let recipientStatus =
      recipient?.status ?? (recipientId && server.db.memberships.find(recipientId)?.status);

    if (recipientStatus === 'invitable') {
      return;
    }

    assert(organization, `Missing 'organization' relationship on 'invite:${invite.id}'`);
    assert(
      !recipient && !recipientId,
      'You are only allowed to pass a `recipient` relationship when their status is `invitable` when creating an invite.'
    );

    /**
     * The membership is an empty shell while awaiting for the invite to be signed and accepted: that's the purpose of `draft` and `status`.
     * A membership with the `draft === true` will be filtered by the API
     * There won't be any user created until the invitee accepts the invitation and enters all the required information.
     */

    let membershipObject = {
      // relationships
      address: null,
      organization,

      // irrelevant data for the invite flow
      birthCity: null,
      birthCountry: null,
      birthZipcode: null,

      // invite data
      birthdate,
      email,
      firstName,
      gender: null,
      lastName,
      phoneNumber,
      role,

      // draft status
      draft: false,
      status: 'pending',
      userId: null,
    };

    if (team) {
      membershipObject.team = team;
    }

    let membership = server.create('membership', membershipObject);

    if (allowedBankAccountsIds) {
      // Create an AllowedBankAccount with a relationship to the specific membership and multiple bank accounts
      server.create('allowed-bank-account', {
        membership,
        bankAccounts: server.schema.bankAccounts.find(allowedBankAccountsIds),
      });
    }

    let data = {
      email,
      sub: 'qonto',
      iss: 'qonto-api',
      nbf: 1617110466,
      exp: 1617112386,
      iat: 1617110586,
      jti: '038746cc7ac5276c65c9bf1095a3adaf',
    };

    let loginToken = sign(data, 'secret');

    invite.update({
      recipient: membership,
      loginToken,
    });
  },
});
