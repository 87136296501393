import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  createdAt: '2021-10-08T10:26:32.654Z',
  updatedAt: '2021-10-08T10:26:32.654Z',

  afterCreate(model) {
    assert(
      model.creation,
      `Missing 'creation' relationship on 'cc-capital-deposit-statement:${model.id}'`
    );
    assert(model.file, `Missing 'file' relationship on 'cc-capital-deposit-statement:${model.id}'`);
  },
});
