import { belongsTo, hasMany, Model } from 'miragejs';

export default Model.extend({
  beneficiary: belongsTo(),
  bankAccount: belongsTo(),
  transaction: belongsTo(),
  initiator: belongsTo('membership'),
  attachments: hasMany(),
  labels: hasMany(),
});
