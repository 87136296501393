import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  sequentialId: i => i + 1,
  bankAccountId: null,
  initiatorId: null,
  slug: null,
  status: 'pending',
  totalAmount: 0,
  afterCreate(transfer) {
    assert(
      transfer.bankAccount,
      `Missing 'bankAccount' relationship on 'multi-transfer:${transfer.id}'`
    );
    assert(
      transfer.initiator,
      `Missing 'initiator' relationship on 'multi-transfer:${transfer.id}'`
    );
    assert(
      transfer.transfers.length !== 0,
      `Missing 'transfers' relationship on 'multi-transfer:${transfer.id}'`
    );

    if (!transfer.slug) {
      let slug = `${transfer.bankAccount.slug}-multi-transfer-${transfer.sequentialId}`;
      transfer.update({ slug });
    }

    if (!transfer.totalAmount) {
      let totalAmount = transfer.transfers.models
        .map(transfer => transfer.amount)
        .reduce((total, amount) => total + amount, 0);

      let totalAmountCents = Math.round(totalAmount * 100);

      transfer.update({ totalAmount, totalAmountCents });
    }
  },
});
