import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  provider: 'fourthline',
  provider_status: 'kyc_required',
  redirect_url:
    'https://kyc-master.staging.qonto.co/v1/?code=9999J059&redirect=https://kyc-master.staging.qonto.co/v1/?code=9999D9FO',
  is_qes_required: true,
  reason: null,

  afterCreate(model) {
    if (!model.stakeholderId) {
      assert(model.stakeholderId, `A stakeholder relationship is required for kyc ${model.id}`);
    }
  },
});
