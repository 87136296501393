import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  getHashForResource() {
    let [hash, addToIncludes] = ApplicationSerializer.prototype.getHashForResource.apply(
      this,
      arguments
    );

    if (Array.isArray(hash)) {
      for (let card of hash) {
        this._adjust(card);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(card) {
    let { schema } = this.registry;

    card.pin_set = Boolean(card.pin);
    card.mask_pan = card.pan.slice(0, 6) + '******' + card.pan.slice(12, 16);
    card.last4 = card.pan.slice(12, 16);
    let paddedMonth = card.exp_month.length !== 2 ? `0${card.exp_month}` : card.exp_month;
    card.expiration_date = `${paddedMonth}/${card.exp_year}`;
    card.expiration_date_short = `${paddedMonth}/${card.exp_year.slice(2, 4)}`;

    if (card.address_id) {
      let addressResource = schema.addresses.find(card.address_id);
      let [addressesHash] = this.getHashForIncludedResource(addressResource);
      card.address = addressesHash.addresses[0];
      delete card.address_id;
    }

    // backward compatibility to cards V3
    if (typeof card.physical === 'boolean') {
      card.body_type = card.physical ? 'physical' : 'virtual';
    }

    delete card.pan;
    delete card.pin;

    let bankAccount = schema.bankAccounts.find(card.bank_account_id);
    card.organization_id = bankAccount.organizationId;
  },
});
