import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  amount: {
    value: 0,
    currency: 'EUR',
  },
  fiscalCode: '00294190160',
  creditorName: 'Comune di Milano',
  noticeNumber: '301000000000025115',
  iuv: null,

  afterCreate(model) {
    assert(model.bankAccount, `Missing 'bankAccount' relationship on 'pagopa-payment:${model.id}'`);

    assert(
      !model.organization || !model.organizationId,
      `You should not pass an 'organization' relationship on 'pagopa-payment:${model.id}'`
    );
  },
});
