import { pluralize } from 'ember-inflector';
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class ClientHubSerializer extends JSONAPISerializer {
  alwaysIncludeLinkageData = true;

  typeKeyForModel({ modelName }) {
    if (modelName === 'client-hub') {
      return 'clients';
    }
    return underscore(pluralize(modelName));
  }

  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(attr) {
    return underscore(attr);
  }

  serialize(_, request) {
    let json = super.serialize(...arguments);

    // BE intentionally wanted to add this new 'has_reminders' attribute only in the responses of GET requests
    if (request.method === 'GET') {
      if (Array.isArray(json.data)) {
        json.data = json.data.map(client => this._addHasReminders(client));
      } else {
        json.data = this._addHasReminders(json.data);
      }
    }
    return json;
  }

  _addHasReminders(client) {
    let { schema } = this.registry;

    client.attributes.has_reminders =
      schema.receivableInvoicesReminders.where({ clientHubId: client.id }).length > 0;
    return client;
  }
}
