import Serializer from './application';

export default Serializer.extend({
  getHashForResource() {
    let [hash, addToIncludes] = Serializer.prototype.getHashForResource.apply(this, arguments);

    if (Array.isArray(hash)) {
      for (let child of hash) {
        this._adjust(child);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(referral) {
    let { schema } = this.registry;
    let referee = referral.refereeId ? schema.organizations.find(referral.refereeId) : null;

    /* Update typo on refereeName */
    referral.refereeName = referee?.name;
    /* Keep for retro compatibility until clean up*/
    referral.referreeName = referee?.name;
  },
});
