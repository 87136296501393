import { pluralize } from 'ember-inflector';
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class PartnerSerializer extends JSONAPISerializer {
  keyForAttribute(attr) {
    // use snake_case for attribute names
    return underscore(attr);
  }

  keyForRelationship(key) {
    // use snake_case for relationship names
    return underscore(key);
  }

  typeKeyForModel({ modelName }) {
    // remove `partner-` prefix from model names
    if (modelName.startsWith('partner-')) {
      modelName = modelName.slice(8);
    }

    return underscore(pluralize(modelName));
  }

  getFieldsParams(resourceKey) {
    return this.request.queryParams[`fields[${resourceKey}]`]?.split(',');
  }

  _attrsForModel(model) {
    let attrs = {};

    let attrsOverrides = this.getFieldsParams(this.typeKeyForModel(model)) || this.attrs;

    if (attrsOverrides) {
      attrs = attrsOverrides.reduce((memo, attr) => {
        memo[attr] = model[attr];
        return memo;
      }, {});
    } else {
      attrs = Object.assign(attrs, model.attrs);
    }

    // Remove fks
    model.fks.forEach(key => delete attrs[key]);

    return this._formatAttributeKeys(attrs);
  }
}
