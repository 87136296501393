import { belongsTo, hasMany, Model } from 'miragejs';

export default Model.extend({
  applicationReviews: hasMany('cc-application-review'),
  capitalDepositReview: belongsTo('cc-capital-deposit-review'),
  capitalDepositStatement: belongsTo('cc-capital-deposit-statement'),
  organization: belongsTo('cc-organization', { inverse: 'creation' }),
  incorporationDocument: belongsTo('cc-incorporation-document'),
  physicalEntities: hasMany('cc-physical-entity'),
  registrationDocument: belongsTo('cc-registration-document'),
  shareholders: hasMany('cc-shareholder'),
  shareholdersDeclaration: belongsTo('cc-shareholders-declaration'),
  user: belongsTo(),
});
