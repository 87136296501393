import ApplicationSerializer from './application';

export default class TeamSerializer extends ApplicationSerializer {
  serialize() {
    let json = super.serialize(...arguments);

    if (json.team) {
      this._adjust(json.team);
    } else if (json.teams) {
      json.teams.forEach(team => this._adjust(team));
    }

    return json;
  }

  _adjust(team) {
    let { schema } = this.registry;
    let memberships = schema.memberships.where({ teamId: team.id });
    let activeMemberships = memberships.filter(({ status }) =>
      ['active', 'pending'].includes(status)
    );

    team.memberships_count = memberships.length;
    team.active_memberships_count = activeMemberships.length;
    team.team_manager_ids = activeMemberships.models.reduce((ids, member) => {
      if (member.role === 'manager') ids.push(member.id);
      return ids;
    }, []);
    return team;
  }
}
