import CompanyCreationSerializer from './-company-creation';

let creationSerializer = CompanyCreationSerializer.extend({
  getHashForPrimaryResource(resource) {
    let [hash, addToIncludes] = CompanyCreationSerializer.prototype.getHashForPrimaryResource.apply(
      this,
      arguments
    );

    // convert `user` relationship to `user_id` attribute
    if (Array.isArray(hash.data)) {
      for (let i = 0; i < hash.data.length; i++) {
        hash.data[i].attributes.user_id = resource.models[i].userId;
      }
    } else {
      hash.data.attributes.user_id = resource.userId;
    }

    return [hash, addToIncludes];
  },
});

export default creationSerializer;
