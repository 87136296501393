import { Factory } from 'miragejs';

import { PRODUCT_ADDON_MONTHLY, PRODUCT_PLAN_ANNUAL } from '../../fixtures/registration-product';

/**
 * https://openapi-master.staging.qonto-cbs.co/biller#tag/RegistrationProductSelection
 */
export default Factory.extend({
  organization_id: '13371337-1337-1337-1337-133713371337',
  registration_flow_type: 'existing_company', // | 'company_creation'
  initial_trial_months: 1,
  legal_country: 'FR',
  voucher_code: '1337VOUCHER', // string | null
  selected_products: [PRODUCT_PLAN_ANNUAL, PRODUCT_ADDON_MONTHLY],
});
