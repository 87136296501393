import PartnerSerializer from './-partner';

export default class PartnerOrganizationSerializer extends PartnerSerializer {
  shouldIncludeLinkageData(relationshipKey) {
    /**
     * Taken from this list :
     * https://gitlab.qonto.co/back/partner-register/-/blob/master/app/serializers/client_api/organization_serializer.rb
     **/

    return relationshipKey === 'registration';
  }
}
