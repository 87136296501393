/**
 * This function checks if `condition` is truthy and if not, it will
 * throw an `Error` with the provided `message`.
 *
 * If QUnit is available it will also add a failing assertion to the current
 * test. This ensures that assertion failures in route handlers not just result
 * in "500 Internal Server Error" responses, but that they definitely will fail
 * the tests.
 *
 * This function should not be used for legitimate API responses. It should only
 * be used to ensure that `@qonto/mirage` is used correctly.
 *
 * The primary example for this is making sure that relationships are
 * correctly assigned.
 */
export function assert(condition, message) {
  if (!condition) {
    if (window.QUnit && !window.__mirage_disable_assert_integration__) {
      window.QUnit.config.current.assert.pushResult({
        result: false,
        message,
      });
    }

    throw new Error(message);
  }
}

/**
 * This function can be used to disable the QUnit integration in the `assert()`
 * function above. This can be useful when you want to test that an
 * `afterCreate()` hook is correctly throwing an error, but should not be used
 * for regular tests!
 */
export function disableAssertIntegration(hooks) {
  hooks.beforeEach(function () {
    window.__mirage_disable_assert_integration__ = true;
  });

  hooks.afterEach(function () {
    delete window.__mirage_disable_assert_integration__;
  });
}
