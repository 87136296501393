import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  keyForModel() {
    return 'subscription';
  },

  serialize(subscription) {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);

    if (json.subscription) {
      let pricePlan = subscription.pricePlan;
      json.subscription.price_plan_code = pricePlan.code;
      json.subscription.price_plan_group_code = pricePlan.groupCode;

      delete json.subscription.treezor_subscription_id;
    }

    return json;
  },
});
