import { KYC_STATUS, STATUS } from '@qonto/mirage/constants/membership';

import ApplicationSerializer from './application';

export default class MembershipSerializer extends ApplicationSerializer {
  include(request) {
    let includes = [];

    let includesQP = request.queryParams.includes || [];

    if (includesQP.includes('membership_kyb_details')) {
      includes.push('membershipKybDetails');
    }

    return includes;
  }

  getHashForResource() {
    let [hash, addToIncludes] = super.getHashForResource(...arguments);

    if (Array.isArray(hash)) {
      for (let membership of hash) {
        this._adjust(membership);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  }

  normalize() {
    let payload = super.normalize(...arguments);

    /**
     * Because of the weirdness of the API, we are generating a fake frontend ID for
     * an address "relationship". For now, we need to ignore the address data that submitted this request.
     *
     * /!\ This prevents update of the membership address. /!\
     */

    delete payload.data.relationships?.address;
    return payload;
  }

  serialize() {
    let json = super.serialize(...arguments);
    let { membership, memberships } = json;

    if (membership) {
      this.mapStatusInvitedToPending(membership);
      return json;
    }

    if (memberships?.length) {
      memberships.map(this.mapStatusInvitedToPending);
    }

    return json;
  }

  /**
   * We need that to make sure it works as BE: https://gitlab.qonto.co/qonto/qonto-api/-/blob/master/app/serializers/v1/membership_serializer.rb#L81
   */
  mapStatusInvitedToPending(membership) {
    membership.status =
      membership.status === STATUS.INVITED ? KYC_STATUS.PENDING : membership.status;
  }

  _adjust(membership) {
    let { schema } = this.registry;

    if (membership.user_id) {
      let userResource = schema.users.find(membership.user_id);
      let [usersHash] = this.getHashForIncludedResource(userResource);
      let user = usersHash.users[0];

      membership.avatar_thumb = user.avatar_thumb;
      membership.avatar = user.avatar;
      membership.birth_city = user.birth_city;
      membership.birth_country = user.birth_country;
      membership.birthdate = user.birthdate;
      membership.birth_zipcode = user.birth_zipcode;
      membership.email ||= user.email;
      membership.first_name = user.first_name;
      membership.gender = user.gender;
      membership.last_name = user.last_name;
      membership.phone_number = user.phone_number;
    }

    if (membership.address_id) {
      let addressResource = schema.addresses.find(membership.address_id);
      let [addressesHash] = this.getHashForIncludedResource(addressResource);
      membership.address = addressesHash.addresses[0];
    }
    delete membership.address_id;

    let activeCardStatuses = [
      'live',
      'ordered',
      'paused',
      'pending',
      'pin_blocked',
      'received',
      'shipped',
    ];

    let activePhysicalCards = schema.cards.where(
      card =>
        card.holderId === membership.id &&
        activeCardStatuses.includes(card.status) &&
        ['standard', 'plus', 'metal'].includes(card.cardLevel)
    );

    membership.physical_cards_limit_exceeded =
      activePhysicalCards.length >= membership.active_physical_cards_limit;

    let initiatorRequests = schema.requestTransfers.where({ initiatorId: membership.id });
    membership.requests_count = initiatorRequests.length;

    if (!membership.rejected_document_types) {
      membership.rejected_document_types = {};
    }
  }
}
