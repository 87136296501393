export function processNeedsReview(updateProcess) {
  // Check if the organization change request exists and needs a review
  let organizationChangeRequest = updateProcess.kycKybUpdateOrganizationChangeRequest;
  if (organizationChangeRequest && organizationNeedsReview(organizationChangeRequest)) {
    return true;
  }

  // Check if the membership change requests exists and any of them need a review
  let membershipChangeRequests = updateProcess.kycKybUpdateMembershipChangeRequests.models;
  for (let membershipChangeRequest of membershipChangeRequests) {
    if (membershipNeedsReview(membershipChangeRequest)) {
      return true;
    }
  }

  return false;
}

function organizationNeedsReview(changeRequest) {
  let { organization } = changeRequest.kycKybUpdateProcess;

  // see https://docs.google.com/spreadsheets/d/1FbG5-rSmGllS7SecLXOnSFJ5lSv8jhkfkUwZIO5P-p4/
  return (
    changeRequest.activityCode !== organization.activityCode ||
    changeRequest.addressCity !== organization.address.city ||
    changeRequest.addressCountry !== organization.address.country ||
    changeRequest.addressFirstLine !== organization.address.firstLine ||
    changeRequest.addressSecondLine !== organization.address.secondLine ||
    changeRequest.addressThirdLine !== organization.address.thirdLine ||
    changeRequest.addressZipCode !== organization.address.zipcode ||
    changeRequest.legalCode !== organization.legalCode ||
    changeRequest.legalName !== organization.legalName ||
    (changeRequest.legalNumber && changeRequest.legalNumber !== organization.legalNumber) ||
    (changeRequest.legalRegistrationDate &&
      changeRequest.legalRegistrationDate !== organization.legalRegistrationDate) ||
    changeRequest.name !== organization.tradeName ||
    changeRequest.taxCountry !== organization.taxResidenceCountry ||
    changeRequest.taxIdentificationNumber !== organization.taxResidenceTaxIdentificationNumber
  );
}

function membershipNeedsReview(changeRequest) {
  let { membership } = changeRequest;

  if (!membership) {
    return true;
  }

  // see https://docs.google.com/spreadsheets/d/1FbG5-rSmGllS7SecLXOnSFJ5lSv8jhkfkUwZIO5P-p4/
  return (
    changeRequest.birthCity !== membership.birthCity ||
    changeRequest.birthCountry !== membership.birthCountry ||
    changeRequest.birthdate !== membership.birthdate ||
    changeRequest.corporateOfficer !== membership.corporateOfficer ||
    changeRequest.firstName !== membership.firstName ||
    changeRequest.lastName !== membership.lastName ||
    changeRequest.legalRepresentative !== membership.legalRepresentative ||
    changeRequest.ubo !== membership.ubo
  );
}
