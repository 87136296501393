import { decamelize } from '@qonto/mirage/utils/string';

import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  getHashForResource() {
    let [hash, addToIncludes] = ApplicationSerializer.prototype.getHashForResource.apply(
      this,
      arguments
    );

    if (Array.isArray(hash)) {
      for (let legalEntity of hash) {
        this._adjust(legalEntity);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(legalEntity) {
    let { schema } = this.registry;

    if (!legalEntity.bank_account_id) {
      legalEntity.bank_account = {};
    } else {
      let bankAccount = schema.bankAccounts.find(legalEntity.bank_account_id);
      legalEntity.bank_account = this._transformKeys(bankAccount.attrs);
    }

    delete legalEntity.bank_account_id;
  },

  _transformKeys(object) {
    let newObject = {};

    for (let key in object) {
      newObject[decamelize(key)] = object[key];
    }

    return newObject;
  },
});
