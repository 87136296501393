import Serializer from './application';

export default Serializer.extend({
  keyForModel() {
    return 'rewards';
  },
  getHashForResource() {
    // let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);
    let [hash, addToIncludes] = Serializer.prototype.getHashForResource.apply(this, arguments);

    if (Array.isArray(hash)) {
      for (let child of hash) {
        this._adjust(child);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(reward) {
    let { schema } = this.registry;

    let referral = schema.referrals.find(reward.referral_id);
    reward.referee_name = referral.refereeName;
  },
});
