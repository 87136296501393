import { camelize } from '@qonto/mirage/utils/string';

export default function transformKeys(object, keyTransform = camelize) {
  if (object === null || typeof object !== 'object') {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map(member => transformKeys(member, keyTransform));
  }

  return Object.keys(object).reduce((obj, key) => {
    obj[keyTransform(key)] = transformKeys(object[key], keyTransform);
    return obj;
  }, {});
}
