const getCommonPartsAddon = isAccountReceivable => ({
  description_has_decoration: false,
  sub_description: isAccountReceivable ? 'Free until March 31, 2025.' : null,
  monthly_price: {
    prefix: isAccountReceivable ? 'Then ' : null,
    per_month_amount: {
      value: '11.00',
      currency: 'EUR',
    },
    per_year_amount: {
      value: '132.00',
      currency: 'EUR',
    },
    period: '/ month (excl. VAT)',
    billing_recurrence: 'Billed monthly',
    annual_benefit: 'Save €24 paying annually',
  },
  annual_price: {
    prefix: isAccountReceivable ? 'Then ' : null,
    per_month_amount: {
      value: '9.00',
      currency: 'EUR',
    },
    per_year_amount: {
      value: '108.00',
      currency: 'EUR',
    },
    period: '/ month (excl. VAT)',
    billing_recurrence: 'Billed annually',
    annual_benefit: 'Billed annually at €108.00 (save €24)',
  },
  benefit_groups: [
    {
      title: 'Included features',
      badge: null,
      benefits: [
        {
          description: 'Recurring invoices for subscription collection',
          description_has_decoration: false,
          is_included: true,
        },
        {
          description: 'Unpaid invoice reminders',
          description_has_decoration: false,
          is_included: true,
        },
      ],
    },
  ],
  feature_groups: [
    {
      title: 'The essentials',
      features: [],
    },
  ],
});

export const ADDON_ACCOUNT_RECEIVABLE = {
  id: 'account_receivable_id',
  type: 'addon',
  code: 'account_receivable',
  brand_name: 'Accounts Receivable',
  description: 'Accounts Receivable',
  ...getCommonPartsAddon(true),
};

export const ADDON_ACCOUNT_PAYABLE = {
  id: 'account_payable_id',
  type: 'addon',
  code: 'account_payable',
  brand_name: 'Accounts Payable',
  description: 'Accounts Payable',
  ...getCommonPartsAddon(false),
};

export const ADDON_EXPENSE_AND_SPEND_MANAGEMENT = {
  id: 'expense_and_spend_management_id',
  type: 'addon',
  code: 'expense_and_spend_management',
  brand_name: 'Expense And Spend Management',
  description: 'Expense And Spend Management',
  ...getCommonPartsAddon(false),
};

const commonParts = {
  description: 'An online business account & app to simplify your day-to-day finances.',
  traits: [
    {
      name: 'popular',
      title: 'Popular',
    },
  ],
  monthly_price: {
    per_month_amount: {
      value: '11.00',
      currency: 'EUR',
    },
    per_year_amount: {
      value: '132.00',
      currency: 'EUR',
    },
    period: '/ month (excl. VAT)',
    billing_recurrence: 'Billed monthly',
    annual_benefit: 'You save €24 paying annually',
  },
  annual_price: {
    per_month_amount: {
      value: '9.00',
      currency: 'EUR',
    },
    per_year_amount: {
      value: '108.00',
      currency: 'EUR',
    },
    period: '/ month (excl. VAT)',
    billing_recurrence: 'Billed annually',
    annual_benefit: 'You save €24 by paying annually',
  },
  trial_duration: '1 month free trial',
  benefit_groups: [
    {
      title: 'Collaboration & Support',
      benefits: [
        {
          badge: 'New',
          description: '7-day-a-week support',
          is_included: true,
        },
      ],
    },
  ],

  addons: [ADDON_ACCOUNT_RECEIVABLE, ADDON_ACCOUNT_PAYABLE],
};

export const PRICE_PLAN_SOLO_BASIC = {
  id: 'solo_basic_2024_id',
  code: 'solo_basic_2024',
  brand_name: 'Basic',
  lineup: 'solo',
  ...commonParts,
};

export const PRICE_PLAN_SOLO_SMART = {
  id: 'solo_smart_2024_id',
  code: 'solo_smart_2024',
  brand_name: 'Smart',
  lineup: 'solo',
  ...commonParts,
};

export const PRICE_PLAN_SOLO_PREMIUM = {
  id: 'solo_premium_2024_id',
  code: 'solo_premium_2024',
  brand_name: 'Premium',
  lineup: 'solo',
  ...commonParts,
};

export const PRICE_PLAN_TEAM_ESSENTIAL = {
  id: 'team_essential_2024_id',
  code: 'team_essential_2024',
  brand_name: 'Team Essential',
  lineup: 'teams',
  ...commonParts,
};

export const PRICE_PLAN_TEAM_BUSINESS = {
  id: 'team_business_2024_id',
  code: 'team_business_2024',
  brand_name: 'Team Business',
  lineup: 'teams',
  ...commonParts,
};

export const PRICE_PLAN_TEAM_ENTERPRISE = {
  id: 'team_enterprise_2024_id',
  code: 'team_enterprise_2024',
  brand_name: 'Team Enterprise',
  lineup: 'teams',
  ...commonParts,
};
