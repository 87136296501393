import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class CustomerSerializer extends JSONAPISerializer {
  alwaysIncludeLinkageData = true;

  keyForAttribute(attr) {
    return underscore(attr);
  }
}
