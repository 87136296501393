import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  afterCreate(multiBeneficiary) {
    assert(
      multiBeneficiary.bankAccount,
      `Missing 'bankAccount' relationship on 'multi-beneficiary:${multiBeneficiary.id}'`
    );

    assert(
      !multiBeneficiary.organization && !multiBeneficiary.organizationId,
      `You should not pass an 'organization' relationship on 'multi-beneficiary:${multiBeneficiary.id}'`
    );
  },
});
