import ApplicationSerializer from './application';

const INCLUDE_MAPPING = {
  beneficiaries: 'beneficiary',
  attachments: 'attachment',
};

export default ApplicationSerializer.extend({
  include(request) {
    return (request.queryParams.includes || []).map(it => INCLUDE_MAPPING[it]).filter(Boolean);
  },

  getHashForResource() {
    let [hash, addToIncludes] = ApplicationSerializer.prototype.getHashForResource.apply(
      this,
      arguments
    );

    if (Array.isArray(hash)) {
      for (let mandate of hash) {
        this._adjust(mandate);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(mandate) {
    let { schema } = this.registry;

    let bankAccount = schema.bankAccounts.find(mandate.bank_account_id);
    mandate.organization_id = bankAccount.organizationId;
    mandate.successful_direct_debits_count = mandate.direct_debit_ids.length;

    if (mandate.mandates_counterparty_id) {
      let resource = schema.mandatesCounterparties.find(mandate.mandates_counterparty_id);
      let [hash] = this.getHashForIncludedResource(resource);
      mandate.mandates_counterparty = hash.mandates_counterparties[0];
      delete mandate.mandates_counterparty_id;
    }
  },
});
