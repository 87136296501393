import { Factory, trait } from 'miragejs';

import { md5 } from '@qonto/mirage/utils/-md5';

export default Factory.extend({
  avatarThumbUrl: null,
  avatarUrl: null,
  birthCity: 'Wintheiserland',
  birthCountry: 'SV',
  birthZipcode: '34305',
  birthdate: '1974-02-26',
  canEnrollTrustedDeviceUsingSmsOtp: false,
  defaultAvatarThumbUrl: null,
  defaultAvatarUrl: null,
  email: 'john.doe@qonto.eu',
  firstName: 'John',
  futuraeUserId: null,
  gender: 'male',
  lastName: 'Doe',
  locale: 'en',
  mfaEnabled: false,
  mfaMethod: 'by_sms',
  onboarded: true,
  password: () => generatePassword(),
  pendingEmail: 'toto@test.eu',
  pendingPhoneNumber: null,
  phoneNumber: '+33999099999',
  phoneNumberVerified: true,
  resetPasswordToken: null,
  scaEnabled: false,
  scaEnrollmentAvailable: true,
  smsTriggerToken: null,
  segmentId: null,
  timezone: 'Europe/Paris',
  flags: {
    has_password: true,
  },

  fullName() {
    return `${this.firstName} ${this.lastName}`;
  },

  empty: trait({
    birthCity: null,
    birthCountry: null,
    birthZipcode: null,
    birthdate: null,
    firstName: null,
    gender: null,

    lastName: null,
    mfaEnabled: false,
    mfaMethod: null,
    onboarded: false,
    pendingEmail: null,
    pendingPhoneNumber: null,
    phoneNumber: null,
    phoneNumberVerified: false,
    scaEnabled: false,
    timezone: 'Europe/Paris',
    zendesk: null,
  }),

  afterCreate(model) {
    if (!model.avatarThumbUrl) {
      let avatarThumbUrl = `https://qonto.dev/user/${model.id}/thumb.png`;
      model.update({ avatarThumbUrl });
    }
    if (!model.avatarUrl) {
      let avatarUrl = `https://qonto.dev/user/${model.id}/large.png`;
      model.update({ avatarUrl });
    }
    if (!model.defaultAvatarThumbUrl) {
      let defaultAvatarThumbUrl = `https://qonto.dev/user/${model.id}/default_thumb.png`;
      model.update({ defaultAvatarThumbUrl });
    }
    if (!model.defaultAvatarUrl) {
      let defaultAvatarUrl = `https://qonto.dev/user/${model.id}/default_large.png`;
      model.update({ defaultAvatarUrl });
    }
    if (!model.segmentId) {
      model.update({ segmentId: md5(model.id, 'segment') });
    }
    model.update({
      zendesk: {
        messaging_widget_jwt: `jwt-messaging-${model.id}`,
        sso_jwt: `jwt-sso-${model.id}`,
      },
    });
  },
});

function generatePassword() {
  return Math.random().toString().slice(2);
}
