import { processNeedsReview } from '@qonto/mirage/utils/kyc-kyb-update';

import KycKybUpdate from './-kyc-kyb-update';

export default KycKybUpdate.extend({
  getHashForResource() {
    let hash = KycKybUpdate.prototype.getHashForResource.apply(this, arguments);

    // Find the corresponding `UpdateProcess` in the schema
    let updateProcess = this.registry.schema.kycKybUpdateProcesses.find(hash.id);

    // Add `needs_review` field to the serialization output
    hash.attributes.needs_review = processNeedsReview(updateProcess);

    return hash;
  },
});
