import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  sequentialId: i => i + 1,
  email: i => `allowed-${i + 1}@acme.com`,

  afterCreate(allowedEmail) {
    assert(
      allowedEmail.organization,
      `Missing 'organization' relationship on 'allowed-email:${allowedEmail.id}'`
    );
  },
});
