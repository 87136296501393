import { Factory } from 'miragejs';

import { PROVIDER_NAME, TRANSFER_STATUSES } from '@qonto/mirage/constants/international-out';
import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  provider: PROVIDER_NAME,
  providerObjectId: () => crypto.randomUUID(),
  status: TRANSFER_STATUSES.INITIATED,
  sourceAmount: {
    currency: 'EUR',
    value: '10.00',
  },
  targetAmount: {
    currency: 'USD',
    value: '12.00',
  },

  afterCreate(model) {
    assert(
      model.bankAccount,
      `Missing 'bankAccount' relationship on 'international-out-transfer:${model.id}'`
    );
    assert(
      model.beneficiary,
      `Missing 'beneficiary' relationship on 'international-out-transfer:${model.id}'`
    );
    assert(
      model.initiator,
      `Missing 'initiator' relationship on 'international-out-transfer:${model.id}'`
    );
    assert(
      model.organization,
      `Missing 'organization' relationship on 'international-out-transfer:${model.id}'`
    );
  },
});
