import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

import PartnerSerializer from './-partner';

export default class RegistrationsSerializer extends PartnerSerializer {
  keyForAttribute(attr) {
    // use snake_case for attribute names
    return underscore(attr);
  }

  shouldIncludeLinkageData(relationshipKey) {
    /**
     * Taken from this list :
     * https://gitlab.qonto.co/back/partner-register/-/blob/master/app/serializers/client_api/registration_serializer.rb
     **/
    return ['stakeholders', 'organization'].includes(relationshipKey);
  }

  serialize() {
    let json = JSONAPISerializer.prototype.serialize.apply(this, arguments);

    // Populate is_paid property
    if (Array.isArray(json.data)) {
      for (let registration of json.data) {
        this._isPaid(registration);
      }
    } else {
      this._isPaid(json.data);
    }

    return json;
  }

  _isPaid(registration) {
    let { schema } = this.registry;
    let hasAtLeastOneSucceededPayment = schema.paymentIntents.findBy({
      registrationId: registration.id,
      status: 'succeeded',
    });
    registration.attributes.is_paid = Boolean(hasAtLeastOneSucceededPayment);
  }
}
