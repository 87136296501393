import { Factory, trait } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

/**
 * @see http://qonto.pages.qonto.co/qonto-register/v1/#the-organization-object
 */
export default Factory.extend({
  activityCode: null,
  addressCity: 'Paris',
  addressContactName: null,
  addressCountry: 'FR',
  addressLine1: '20 B Rue la Fayette',
  addressLine2: null,
  addressZipcode: '75009',
  allowedActivity: null,
  annualTurnover: null,
  associationTypeformUrl: null,
  companyCreationFromScratch: false,
  coreBankingSystem: 'qonto',
  crsFlagged: false,
  declaredNumberOfEmployeeRange: null,
  initialOrganizationType: null,
  draft: true,
  legalAnnualTurnOver: null,
  legalCountry: 'FR',
  legalFormCreditsafeCode: null,
  legalName: null,
  legalNetIncomeRange: null,
  legalNumber: null,
  legalNumberOfEmployeeRange: null,
  legalRegistrationDate: null,
  legalSector: null,
  legalShareCapitalCents: null,
  legalShareCapitalCurrency: null,
  legalTvaNumber: null,
  maxSinglePaymentAmount: null,
  name: i => `Company ${i + 1}`,
  natureOfOperations: () => [],
  originalPricePlanCode: 'essential',
  originalRecurrence: 'monthly',
  originalVoucherCode: null,
  originalVoucherRecurrence: null,
  providerSource: null,
  providerSourceContactEmail: null,
  referralCode: null,
  sectorCode: null,
  underRegistration: false,
  unreferenced: true,
  yearlyTransactionVolume: null,
  gbrManagementType: null, // single, joint

  empty: trait({
    addressCity: null,
    addressLine1: null,
    addressZipcode: null,
    name: null,
    originalPricePlanCode: null,
  }),

  afterCreate(model) {
    assert(model.user, `Missing 'user' relationship on 'register-organization:${model.id}'`);

    if (!model.legalName) {
      model.update({ legalName: model.name });
    }
  },
});
