import { Factory } from 'miragejs';

export default Factory.extend({
  deviceType: 'browser',
  lastVisitBrowser: 'Chrome',
  lastVisitCity: null,
  lastVisitCountry: null,
  lastVisitCreatedAt: () => new Date().toISOString(),
  lastVisitDeviceType: 'Desktop',
  lastVisitIp: '172.18.0.23',
  lastVisitOs: 'Mac OS X',
  lastVisitRegion: null,
  lastVisitUserAgent:
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:81.0) Gecko/20100101 Firefox/81.0',
});
