import { ActiveModelSerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class ReceivableCreditNotesSharedReceivableCreditNotesSerializer extends ActiveModelSerializer {
  include = ['organization'];
  embed = true;
  serializeIds = 'never';

  keyForAttribute(attr) {
    return underscore(attr);
  }

  serializerFor(type) {
    return super.serializerFor(`receivable-credit-notes/shared/${type}`);
  }

  serialize(object) {
    let { receivable_credit_note: attributes } = super.serialize(...arguments);
    let { id } = attributes;

    if (!attributes.contact_email) {
      attributes.contact_email = object.organization.contactEmail;
    }
    attributes.pdf_url = attributes.url;
    delete attributes.id;
    delete attributes.url;
    return {
      data: {
        id,
        type: 'receivable_credit_notes',
        attributes,
      },
    };
  }
}
