import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class PartnerKycSerializer extends JSONAPISerializer {
  keyForAttribute(attr) {
    // use snake_case for attribute names
    return underscore(attr);
  }

  typeKeyForModel({ modelName }) {
    // remove `partner-` prefix from model names
    if (modelName.startsWith('partner-')) {
      modelName = modelName.slice(8);
    }

    return modelName;
  }

  keyForRelationship(key) {
    // use snake_case for relationship names
    return key.slice(8);
  }
}
