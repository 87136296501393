import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  afterCreate(model) {
    assert(model.offices.models.length !== 0, 'A `company` needs at least one `office`');

    assert(
      model.offices.models.every(o => o.country === model.offices.models[0].country),
      'Every `office` should have the same country'
    );
  },
});
