import { Factory } from 'miragejs';

import { IBAN } from '@qonto/mirage/utils/-ibankit';

export default Factory.extend({
  amount: 0,
  amountCurrency: 'EUR',
  bic: 'BNPDFRP1',
  bulkId: i => i,
  errors: () => {},
  iban: () => IBAN.random('FR').value,
  name: 'John Doe',
  notifyByEmail: false,
  operationType: 'scheduled',
  reference: i => `Reference ${i + 1}`,
  selected: false,
});
