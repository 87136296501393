import { Factory, trait } from 'miragejs';

import { BANK_CONNECTION_PROVIDER, BANK_CONNECTION_STATUS } from '../../constants/bank-connection';

export default Factory.extend({
  name: i => `Bank ${i + 1}`,
  bic: 'BNPAFRPPXXX',
  logo: {
    small: 'https://qonto.co/small.png',
    medium: 'https://qonto.co/medium.png',
  },
  accounts: [{ name: 'Main account' }, { name: 'Savings account' }],
  granted_at: () => new Date().toISOString(),
  expires_at: () => new Date(Date.now() + 180 * 24 * 60 * 60 * 1000).toISOString(), // Consents typically expire after 180 days
  status: BANK_CONNECTION_STATUS.SYNCHRONIZED,
  provider_id: BANK_CONNECTION_PROVIDER.POWENS,
  last_successful_sync_at: () => new Date(Date.now() - 8 * 60 * 1000).toISOString(), // Sync occurs every 8 hours

  withConnectorId: trait({
    connector_id: () => crypto.randomUUID(),
  }),
});
