import { pluralize } from 'ember-inflector';
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class PartnerRegistrationSerializer extends JSONAPISerializer {
  keyForAttribute(attr) {
    return underscore(attr);
  }

  typeKeyForModel({ modelName }) {
    if (modelName === 'register-organization') {
      modelName = 'organization';
    }

    return underscore(pluralize(modelName));
  }

  shouldIncludeLinkageData(relationshipKey) {
    return ['organization'].includes(relationshipKey);
  }
}
