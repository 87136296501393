import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class ReceivableInvoiceSerializer extends JSONAPISerializer {
  alwaysIncludeLinkageData = true;

  serialize() {
    let json = super.serialize(...arguments);

    if (json.data.attributes?.customer_snapshot) {
      json.data.attributes.customer_snapshot = adjustCustomerSnapshotCasing(
        json.data.attributes.customer_snapshot
      );
    }
    if (json.data.attributes?.items) {
      json.data.attributes.items = json.data.attributes.items.map(adjustItemsCasing);
    }

    if (Array.isArray(json.data)) {
      for (let invoice of json.data) {
        if (invoice.attributes?.customer_snapshot) {
          invoice.attributes.customer_snapshot = adjustCustomerSnapshotCasing(
            invoice.attributes.customer_snapshot
          );
        }
        if (invoice.attributes?.items) {
          invoice.attributes.items = invoice.attributes.items.map(adjustItemsCasing);
        }
      }
    }

    return json;
  }

  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(attr) {
    return underscore(attr);
  }
}

function adjustCustomerSnapshotCasing(customerSnapshot) {
  let newCustomerSnapshot = {};
  for (let key of Object.keys(customerSnapshot)) {
    newCustomerSnapshot[underscore(key)] = customerSnapshot[key];
  }
  return newCustomerSnapshot;
}

function adjustItemsCasing(item) {
  let newItem = {};
  for (let key of Object.keys(item)) {
    newItem[underscore(key)] = item[key];
  }
  return newItem;
}
