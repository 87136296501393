export const API = 'https://api.dev.qonto.co';
export const PRICING_CATALOG = 'https://pricing-catalog.dev.qonto.co';
export const AUTH = 'https://auth.dev.qonto.co';
export const BILLER = 'https://biller.dev.qonto.co';
export const COMPANYCREATION = 'https://company-creation-de.dev.qonto.co';
export const REGISTER = 'https://register.dev.qonto.co';
export const NOTIFIER = 'https://notifier-api.dev.qonto.co';
export const HYDRA = 'https://oauth.dev.qonto.co';
export const ATTACHMENT_COLLECTOR = 'https://attachment-collector.dev.qonto.co';
export const PARTNER_REGISTER = 'https://partner-register.dev.qonto.co';
export const QONTO_PILOT = 'https://qonto-pilot.dev.qonto.co';
