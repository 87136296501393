import { pluralize } from 'ember-inflector';
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class RequiredDocumentSerializer extends JSONAPISerializer {
  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(key) {
    return underscore(pluralize(key));
  }

  typeKeyForModel() {
    return 'document_collection_required_document';
  }
}
