import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  createdAt: '2021-03-28T10:26:32.654Z',
  status: 'submitted',
  updatedAt: '2021-03-28T10:26:32.654Z',

  afterCreate(model) {
    assert(
      model.creation,
      `Missing 'creation' relationship on 'cc-capital-deposit-review:${model.id}'`
    );
  },
});
