import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  createdAt: '2021-03-22T15:26:57.654Z',
  status: 'pending',

  afterCreate(model) {
    assert(model.organization, `Missing 'organization' relationship on 'cc-creation:${model.id}'`);
    assert(model.user, `Missing 'user' relationship on 'cc-creation:${model.id}'`);
  },
});
