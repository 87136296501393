import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  amount: {
    value: '160',
    currency: 'EUR',
  },
  status: {
    value: 'earned',
    date: '2023-09-29',
  },
  waitingForKyb: false,

  afterCreate(reward) {
    assert(reward.referral, `Missing 'referrer' relationship on 'referral:${reward.id}'`);
  },
});
