import CompanyCreationSerializer from './-company-creation';

export default CompanyCreationSerializer.extend({
  normalize(/* json */) {
    let json = CompanyCreationSerializer.prototype.normalize.apply(this, arguments);

    // Rewrite `physical_entities` -> `cc_physical_entities` & `legal_entities` ->
    // `cc_legal_entities`, because the model names inside Mirage have the `cc_` prefix, so it
    // wouldn't be able to figure on its own.
    if (json.data.relationships?.entity?.data) {
      let { data } = json.data.relationships.entity;
      data.type = `cc_${data.type}`;
    }
    return json;
  },
});
