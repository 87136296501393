import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  keyForModel() {
    return 'organization';
  },

  normalize(json) {
    delete json.tracking_event;
    return ApplicationSerializer.prototype.normalize.apply(this, arguments);
  },

  getHashForResource() {
    let [hash, addToIncludes] = ApplicationSerializer.prototype.getHashForResource.apply(
      this,
      arguments
    );

    if (Array.isArray(hash)) {
      for (let child of hash) {
        this._adjust(child);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(organization) {
    let { schema } = this.registry;
    let inCardShortage = schema.mirageConfigs.findBy({ key: 'inCardShortage' })?.value ?? false;

    organization.address_line_1 = organization.address_line1;
    organization.address_line_2 = organization.address_line2;
    organization.in_card_shortage = inCardShortage;

    delete organization.address_line1;
    delete organization.address_line2;
    delete organization.draft;
  },
});
