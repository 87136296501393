import { Factory } from 'miragejs';

export default Factory.extend({
  amount: 9.0,
  code: 'lfxgjA',
  recurrence: 'monthly',
  monthDuration: 1,
  status: 'active',
  feeType: null,
  voucherType: 'subscription',
  notUsed: true,
  voucherRateLimitReached: false,
});
