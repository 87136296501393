export const STATUS = {
  DECLINED: 'declined',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

export const RECURRING_TYPES = {
  RECURRING: 'recurring',
  ONE_OFF: 'one_off',
};

export const SDD_MANDATE_STATUS = {
  PENDING: 'pending_signature',
  APPROVED: 'approved',
  CANCELED: 'canceled',
  DECLINED: 'declined',
};

export const CLIENT_KIND = {
  COMPANY: 'company',
  INDIVIDUAL: 'individual',
  FREELANCER: 'freelancer',
};

export const FREQUENCY_UNIT = {
  WEEKS: 'weeks',
  MONTHS: 'months',
  YEARS: 'years',
};

export const SCHEDULE_TYPE = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
};
