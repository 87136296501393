import ApplicationSerializer from './application';
import ColumnsSerializer from './transaction-export-template-column';

export default ApplicationSerializer.extend({
  serialize(collection, request) {
    let json = ApplicationSerializer.prototype.serialize.call(this, collection, request);

    if (json.transaction_export_templates) {
      let columns = this.schema.transactionExportTemplateColumns.all();
      let meta = ColumnsSerializer.prototype.serialize.call(this, columns, { method: 'GET' });

      return {
        export_templates: json.transaction_export_templates,
        meta,
      };
    } else if (json.transaction_export_template) {
      return {
        export_template: json.transaction_export_template,
      };
    } else {
      throw new Error('This line should be unreachable.');
    }
  },
});
