import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export const VALID_STEPS = [
  'owner_documents_review',
  'share_capital_deposit',
  'company_registration',
];

export const CAPITAL_DEPOSIT_METHODS = ['transfer_to_qonto', 'transfer_to_notary', 'cash', 'check'];

export default Factory.extend({
  lastCompletedStep: null,
  capitalDepositMethod: null,

  afterCreate(model) {
    assert(
      model.organization,
      `Missing 'organization' relationship on 'cc-italian-creation:${model.id}'`
    );

    assert(model.user, `Missing 'user' relationship on 'cc-italian-creation:${model.id}'`);

    if (model.lastCompletedStep) {
      assert(VALID_STEPS.includes(model.lastCompletedStep), 'You must pass a valid step name');
    }

    if (model.capitalDepositMethod) {
      assert(
        CAPITAL_DEPOSIT_METHODS.includes(model.capitalDepositMethod),
        'You must pass a valid capital deposit method'
      );
    }
  },
});
