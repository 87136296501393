export const USER_ACTIONS_STATUS = {
  ENABLED: 'enabled',
  DISMISSED: 'dismissed',
};

export const USER_ACTIONS_TYPE = {
  SETUP: 'setup',
  REQUIRED: 'required',
  DISCOVERY: 'discovery',
};
