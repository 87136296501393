import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  createdAt: '2021-03-22T15:26:57.654Z',
  notaryEmail: null,
  status: 'submitted',
  updatedAt: '2021-03-22T15:26:57.654Z',

  afterCreate(model) {
    assert(
      model.creation,
      `Missing 'creation' relationship on 'cc-incorporation-document:${model.id}'`
    );
    assert(
      model.files.models.length !== 0,
      `Missing 'files' relationship on 'cc-incorporation-document:${model.id}'`
    );
  },
});
