export default [
  {
    id: 'csv_extended',
    slug: 'csv_extended',
    type: 'default',
  },
  {
    id: 'xls_extended',
    slug: 'xls_extended',
    type: 'default',
  },
  {
    id: 'csv_simple',
    slug: 'csv_simple',
    type: 'default',
  },
  {
    id: 'xls_simple',
    slug: 'xls_simple',
    type: 'default',
  },
  {
    id: 'ofx',
    slug: 'ofx',
    type: 'default',
  },
  {
    id: 'qif',
    slug: 'qif',
    type: 'default',
  },
  {
    id: 'mt940',
    slug: 'mt940',
    type: 'default',
  },
  {
    id: 'standard_mt940',
    slug: 'standard_mt940',
    type: 'default',
  },
  {
    id: 'datev_mt940',
    slug: 'datev_mt940',
    type: 'default',
  },
  {
    id: 'xls_teamsystem',
    slug: 'xls_teamsystem',
    type: 'default',
  },
  {
    id: 'cb784767-6b70-4960-8e67-8dc197b634e0',
    name: 'My Custom Template',
    type: 'custom',
    format: 'csv',
    separator: ',',
    export_full_data_set: false,
    creator_name: 'Michael Scott',
    created_at: '2023-06-01T00:00:00.000Z',
    updated_at: '2023-06-01T01:00:00.000Z',
    columns: [
      {
        id: 'settlement_date_local',
        position: 1,
        label: 'Settlement date (local)',
      },
      {
        id: 'amount',
        position: 2,
        label: 'Amount',
      },
    ],
  },
];
