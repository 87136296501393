import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  keyForModel: () => 'spend-limit',

  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);
    let limits = json['spend-limit'];

    let spendLimits = {};

    if (limits.per_transfer_limit_cents) {
      spendLimits.per_transfer_limit = {
        value: limits.per_transfer_limit_cents,
        currency: limits.currency,
      };
    }

    if (limits.monthly_transfer_limit_cents !== null) {
      spendLimits.monthly_transfer_limit = {
        value: limits.monthly_transfer_limit_cents,
        currency: limits.currency,
      };

      spendLimits.current_month_spendings = {
        value: limits.current_months_transfers_total_amount,
        currency: limits.currency,
      };
    }

    return spendLimits ? { spend_limits: spendLimits } : null;
  },
});
