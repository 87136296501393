import { Factory } from 'miragejs';

export default Factory.extend({
  paidInstallments: 0,
  totalInstallments: 3,
  completedAt: null,
  activityTag: 'financing',
  financedAmount: {
    value: '950.50',
    currency: 'EUR',
  },
  repaymentStatus: null,
  nextInstallmentTotalAmount: {
    value: '300.50',
    currency: 'EUR',
  },
  nextInstallmentAt: '2024-03-28T17:15:37.288Z',
  totalAmount: {
    value: '1000.50',
    currency: 'EUR',
  },
  beneficiaryName: 'Apple',
  beneficiaryLogo: {
    small: 'https://qonto.com/brand_logos/my-small-logo.png',
    medium: 'https://qonto.com/brand_logos/my-medium-logo.png',
  },
  bankAccountName: 'Bank account',
  createdAt: '2024-02-28T17:15:37.288Z',
  updatedAt: '2024-02-28T17:15:37.288Z',
});
