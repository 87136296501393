import { Factory } from 'miragejs';

export default Factory.extend({
  debitorName: i => `Debitor Name ${i + 1}`,
  uniqueMandateReference: i => `mandate${i + 1}`,
  debitorIban: 'FR76300010079412effez23424',
  amount: {
    value: '100',
    currency: 'EUR',
  },
  reference: '',
  note: '',
  activityTag: 'other_income',
  scheduledDate: '2023-11-20',
  mandateSignatureDate: null,
});
