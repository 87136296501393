import { Model } from 'miragejs';

/**
 * This is a mirage-only model, that is used to set global configuration for
 * our mirage setup.
 *
 * The following config keys are currently available:
 *
 * - kycProvider: set what KYC provider is used by default when creating
 *   new stakeholders (default: qonto)
 *
 * - inCardShortage: whether there is currently a card shortage (default: false)
 */
export default Model.extend();
