import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';
import { generateWebsocketToken, in30min } from '@qonto/mirage/utils/session';

export default Factory.extend({
  expires: () => in30min().toISOString(),
  websocketToken: () => generateWebsocketToken(),

  afterCreate(session) {
    assert(session.user, `Missing 'user' relationship on 'mirage-session:${session.id}'`);
  },
});
