import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);
    let { consent, consents } = json;

    if (consent) {
      this.editAttributes(consent);
    }

    if (consents?.length) {
      consents.map(this.editAttributes);
    }

    return json;
  },

  editAttributes(consent) {
    consent.client_id = consent.public_api_client_id;
    delete consent.public_api_client_id;
  },
});
