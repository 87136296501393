export const v1Countries = [
  {
    isoCode: 'AU',
    defaultCurrency: 'AUD',
    supportedCurrencies: ['AUD'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'AT',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'BE',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'CA',
    defaultCurrency: 'CAD',
    supportedCurrencies: ['CAD'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'CY',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'EE',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'FI',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['FI'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'FR',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'DE',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'GR',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'IE',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['IE'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'IL',
    defaultCurrency: 'ILS',
    supportedCurrencies: ['ILS'],
    accountTypes: ['iban', 'bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'IT',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'LV',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'LT',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'LU',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'MT',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'NL',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'NZ',
    defaultCurrency: 'NZD',
    supportedCurrencies: ['NZD'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'PT',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'SK',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'SI',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'ES',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'BG',
    defaultCurrency: 'EUR',
    supportedCurrencies: ['EUR', 'USD'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'HR',
    defaultCurrency: 'HRK',
    supportedCurrencies: ['HRK'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'CZ',
    defaultCurrency: 'CZK',
    supportedCurrencies: ['CZK'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'DK',
    defaultCurrency: 'DKK',
    supportedCurrencies: ['DKK'],
    accountTypes: ['iban', 'bic_swift'],
    sepa: true,
  },
  {
    isoCode: 'HU',
    defaultCurrency: 'HUF',
    supportedCurrencies: ['HUF'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'PL',
    defaultCurrency: 'PLN',
    supportedCurrencies: ['PLN'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'RO',
    defaultCurrency: 'RON',
    supportedCurrencies: ['RON'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'SE',
    defaultCurrency: 'SEK',
    supportedCurrencies: ['SEK'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'SG',
    defaultCurrency: 'USD',
    supportedCurrencies: ['USD'],
    accountTypes: ['bic_swift'],
    sepa: true,
    supported_currencies: [],
  },
  {
    isoCode: 'GB',
    defaultCurrency: 'GBP',
    supportedCurrencies: ['GBP'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'CN',
    defaultCurrency: 'CNY',
    supportedCurrencies: ['CNY'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'HK',
    defaultCurrency: 'HKD',
    supportedCurrencies: ['HKD'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'JP',
    defaultCurrency: 'JPY',
    supportedCurrencies: ['JPY'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'NO',
    defaultCurrency: 'NOK',
    supportedCurrencies: ['NOK'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'CH',
    defaultCurrency: 'CHF',
    supportedCurrencies: ['CHF'],
    accountTypes: ['iban'],
    sepa: true,
  },
  {
    isoCode: 'US',
    defaultCurrency: 'USD',
    supportedCurrencies: ['USD'],
    accountTypes: ['aba', 'bic_swift'],
    sepa: false,
  },
];

export const otherCountries = [
  {
    isoCode: 'AE',
    defaultCurrency: 'AED',
    supportedCurrencies: ['AED', 'USD'],
    accountTypes: ['iban'],
    sepa: false,
  },
  {
    isoCode: 'BH',
    defaultCurrency: 'BHD',
    supportedCurrencies: ['BHD', 'USD'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'BO',
    defaultCurrency: 'USD',
    supportedCurrencies: ['USD'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'CI',
    defaultCurrency: 'USD',
    supportedCurrencies: ['USD'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'CM',
    defaultCurrency: 'USD',
    supportedCurrencies: ['USD'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'EG',
    defaultCurrency: 'USD',
    supportedCurrencies: ['USD'],
    accountTypes: ['iban'],
    sepa: false,
  },
  {
    isoCode: 'JO',
    defaultCurrency: 'USD',
    supportedCurrencies: ['USD'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'KW',
    defaultCurrency: 'USD',
    supportedCurrencies: ['USD'],
    accountTypes: ['iban'],
    sepa: false,
  },
  {
    isoCode: 'MA',
    defaultCurrency: 'USD',
    supportedCurrencies: ['USD'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'ML',
    defaultCurrency: 'USD',
    supportedCurrencies: ['USD'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'OM',
    defaultCurrency: 'USD',
    supportedCurrencies: ['USD'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'QA',
    defaultCurrency: 'USD',
    supportedCurrencies: ['USD'],
    accountTypes: ['iban'],
    sepa: false,
  },
  {
    isoCode: 'RS',
    defaultCurrency: 'SAR',
    supportedCurrencies: ['SAR', 'USD'],
    accountTypes: ['iban'],
    sepa: false,
  },
  {
    isoCode: 'SA',
    defaultCurrency: 'USD',
    supportedCurrencies: ['USD'],
    accountTypes: ['iban'],
    sepa: false,
  },
  {
    isoCode: 'SN',
    defaultCurrency: 'USD',
    supportedCurrencies: ['USD'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'TH',
    defaultCurrency: 'THB',
    supportedCurrencies: ['THB', 'USD'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
  {
    isoCode: 'TN',
    defaultCurrency: 'TND',
    supportedCurrencies: ['TND', 'USD'],
    accountTypes: ['iban'],
    sepa: false,
  },
  {
    isoCode: 'TR',
    defaultCurrency: 'TRY',
    supportedCurrencies: ['TRY', 'USD'],
    accountTypes: ['iban'],
    sepa: false,
  },
  {
    isoCode: 'ZA',
    defaultCurrency: 'ZAR',
    supportedCurrencies: ['ZAR', 'USD'],
    accountTypes: ['bic_swift'],
    sepa: false,
  },
];

export default [...v1Countries, ...otherCountries.map(country => ({ ...country, v1: false }))];
