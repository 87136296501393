import { pluralize } from 'ember-inflector';
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class ProductSerializer extends JSONAPISerializer {
  alwaysIncludeLinkageData = true;

  typeKeyForModel({ modelName }) {
    return underscore(pluralize(modelName));
  }

  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(attr) {
    return underscore(attr);
  }
}
