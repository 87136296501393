import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  proxied: false,

  afterCreate(model) {
    assert(model.bankAccount, `Missing 'bankAccount' relationship on 'f24-payment:${model.id}'`);

    assert(
      !model.organization || !model.organizationId,
      `You should not pass an 'organization' relationship on 'f24-payment:${model.id}'`
    );
  },
});
