import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);

    if (json.supplier_invoice) {
      this._adjust(json.supplier_invoice);
    } else if (Array.isArray(json.supplier_invoices)) {
      json.supplier_invoices.forEach(this._adjust);
    }

    return json;
  },

  _adjust(invoice) {
    let totalAmount = Number(invoice.total_amount?.value);

    if (!isNaN(totalAmount)) {
      invoice.total_amount.value = totalAmount.toFixed(2);
    }
  },
});
