import { association, Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  activePhysicalCardsLimit: 5,
  corporateOfficer: false,
  createdAt: '2019-10-09T07:45:46.648Z',
  documentsToUpload: false,
  draft: false,
  kycStatus: 'accepted',
  kycSubmitted: true,
  personalInfoProvided: true,
  kycRequired: false,
  legalRepresentative: false,
  nationality: 'SO',
  onboardedCard: true,
  originalCardLevel: null,
  quickFilters: null,
  rejectedDocumentTypes: () => ({}),
  revokedAt: null,
  role: 'owner',
  proxy: false,
  status: 'active',
  taxResidenceCountry: null,
  taxResidenceTaxIdentificationNumber: null,
  team: null,
  title: null,
  topupsStatus: null,
  ubo: false,
  unrevokable: false,
  features: () => [],
  permissions: () => ({}),
  organization: association(),
  address: association(),
  user: association(),
  identityId: 'e43b3324-3900-45e2-bff2-9f15ad87f00d',

  afterCreate(model) {
    if (model.userId !== null && model.status !== 'pending') {
      assert(
        model.organization.memberships.filter(it => it.userId === model.userId).length === 1,
        `A membership for user ${model.userId} on organization ${model.organizationId} already exists`
      );
    }

    if (model.status === 'revoked') {
      model.update({
        revoked_at: new Date().toISOString(),
        unrevokable: true,
      });
    }
  },
});
