import { belongsTo, Model } from 'miragejs';

export default Model.extend({
  organization: belongsTo(),
  bankAccount: belongsTo(),
  initiator: belongsTo('membership'),
  approver: belongsTo('membership'),
  holder: belongsTo('membership'),
  card: belongsTo(),
});
