import { Factory } from 'miragejs';

export default Factory.extend({
  beneficiaryIban: null,
  beneficiaryBic: null,
  beneficiaryName: null,
  amount: '100',
  amountCurrency: 'EUR',
  reference: 'string',
  activityTag: 'finance',
  notifyByEmail: null,
  beneficiaryEmail: null,
});
