export const BENEFICIARY_REQUIREMENTS = [
  {
    type: 'swift_code',
    title: 'SWIFT',
    usageInfo: null,
    fields: [
      {
        name: 'Email',
        group: [
          {
            key: 'email',
            name: 'Email',
            type: 'text',
            refreshRequirementsOnChange: false,
            required: false,
            displayFormat: null,
            example: 'example@example.ex',
            minLength: null,
            maxLength: 255,
            validationRegexp:
              "\\s*[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+\\s*",
            validationAsync: null,
            valuesAllowed: null,
          },
        ],
      },
      {
        name: 'Full name of the account holder',
        group: [
          {
            key: 'accountHolderName',
            name: 'Full name of the account holder',
            type: 'text',
            refreshRequirementsOnChange: false,
            required: true,
            displayFormat: null,
            example: '',
            minLength: 2,
            maxLength: 140,
            validationRegexp: '^[0-9A-Za-zÀ-ÖØ-öø-ÿ-_()\'*,.%#^@&{}~<>+$"\\[\\]\\\\ ]+$',
            validationAsync: null,
            valuesAllowed: null,
          },
        ],
      },
      {
        name: 'SWIFT / BIC code',
        group: [
          {
            key: 'swiftCode',
            name: 'SWIFT / BIC code',
            type: 'text',
            refreshRequirementsOnChange: false,
            required: true,
            displayFormat: null,
            example: 'BUKBGB22',
            minLength: 8,
            maxLength: 11,
            validationRegexp: '^[a-zA-Z]{6}(([a-zA-Z0-9]{2})|([a-zA-Z0-9]{5}))$',
            validationAsync: null,
            valuesAllowed: null,
          },
        ],
      },
      {
        name: 'IBAN / Account number',
        group: [
          {
            key: 'accountNumber',
            name: 'IBAN / Account number',
            type: 'text',
            refreshRequirementsOnChange: false,
            required: true,
            displayFormat: null,
            example: '',
            minLength: 4,
            maxLength: 34,
            validationRegexp: '^[a-zA-Z0-9\\s]{4,34}$',
            validationAsync: null,
            valuesAllowed: null,
          },
        ],
      },
    ],
  },
];

export const CURRENCIES = [
  {
    currency_code: 'USD',
    country_code: 'US',
    suggestion_priority: 2,
  },
  {
    currency_code: 'MAD',
    country_code: 'MA',
    suggestion_priority: 1,
  },
  {
    currency_code: 'INR',
    country_code: 'IN',
    suggestion_priority: 0,
  },
  {
    currency_code: 'GBP',
    country_code: 'GB',
    suggestion_priority: null,
  },
  {
    currency_code: 'AED',
    country_code: 'AE',
    suggestion_priority: null,
  },
  {
    currency_code: 'KRW',
    country_code: 'KR',
    suggestion_priority: null,
  },
  {
    currency_code: 'AUD',
    country_code: 'AU',
    suggestion_priority: null,
  },
  {
    currency_code: 'CAD',
    country_code: 'CA',
    suggestion_priority: null,
  },
  {
    currency_code: 'CHF',
    country_code: 'CH',
    suggestion_priority: null,
  },
  {
    currency_code: 'CLP',
    country_code: 'CL',
    suggestion_priority: null,
  },
  {
    currency_code: 'CRC',
    country_code: 'CR',
    suggestion_priority: null,
  },
  {
    currency_code: 'HKD',
    country_code: 'HK',
    suggestion_priority: null,
  },
  {
    currency_code: 'HUF',
    country_code: 'HU',
    suggestion_priority: null,
  },
  {
    currency_code: 'IDR',
    country_code: 'ID',
    suggestion_priority: null,
  },
  {
    currency_code: 'ILS',
    country_code: 'IL',
    suggestion_priority: null,
  },
  {
    currency_code: 'JPY',
    country_code: 'JP',
    suggestion_priority: null,
  },
  {
    currency_code: 'KES',
    country_code: 'KE',
    suggestion_priority: null,
  },
  {
    currency_code: 'MXN',
    country_code: 'MX',
    suggestion_priority: null,
  },
  {
    currency_code: 'MYR',
    country_code: 'MY',
    suggestion_priority: null,
  },
  {
    currency_code: 'SGD',
    country_code: 'SG',
    suggestion_priority: null,
  },
  {
    currency_code: 'THB',
    country_code: 'TH',
    suggestion_priority: null,
  },
  {
    currency_code: 'TRY',
    country_code: 'TR',
    suggestion_priority: null,
  },
  {
    currency_code: 'UYU',
    country_code: 'UY',
    suggestion_priority: null,
  },
  {
    currency_code: 'ZAR',
    country_code: 'ZA',
    suggestion_priority: null,
  },
];

export const MINIMUM_FEE_AMOUNT = '5.00';

export const FEE_RATE = {
  BASIC: 0.8,
  PREMIUM: 0.56,
};

export const PAY_IN = {
  LOCAL: 'BANK_TRANSFER',
};

export const PAY_OUT = {
  INTERAC: 'INTERAC',
  LOCAL: 'BANK_TRANSFER',
  OUR: 'SWIFT_OUR',
  SHA: 'SWIFT',
};

export const PROVIDER_NAME = 'wise';

export const TARGET_ACCOUNT_ID = 123456789;

export const QUOTE_EXPIRATION_TIME_IN_SECONDS = 30 * 60;

export const QUOTE_STATUSES = {
  PENDING: 'PENDING',
};

export const QUOTE_TYPE = {
  SOURCE: 'SOURCE',
  TARGET: 'TARGET',
};

export const RATE_EXPIRATION_TIME_IN_SECONDS = 2 * 24 * 60 * 60;

export const RATE_TYPE = {
  FIXED: 'FIXED',
  FLOATING: 'FLOATING',
};

export const STANDARD_CURRENCIES = ['CHF', 'GBP', 'USD'];

export const TRANSFER_EVENT_STATUSES = {
  AWAITING: 'awaiting',
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress',
};

export const TRANSFER_EVENTS = {
  COMPLETION: 'completion',
  CREATION: 'creation',
  REJECTION: 'rejection',
  SHIPMENT: 'shipment',
  VALIDATION: 'validation',
};

export const TRANSFER_REQUIREMENTS = [
  {
    type: 'transfer',
    fields: [
      {
        name: 'Transfer reference',
        group: [
          {
            key: 'reference',
            name: 'Transfer reference',
            type: 'text',
            refreshRequirementsOnChange: false,
            required: false,
            displayFormat: null,
            example: null,
            minLength: null,
            maxLength: 10,
            validationRegexp: '[a-zA-Z0-9- ]*',
            validationAsync: null,
            valuesAllowed: null,
          },
        ],
      },
    ],
  },
];

export const TRANSFER_STATUSES = {
  INITIATED: 'INITIATED',
};

export const SPEND_LIMIT_WARNINGS = {
  INSUFFICIENT_MONTHLY_LIMIT: 'insufficient_monthly_limits',
  INSUFFICIENT_PER_TRANSFER_LIMIT: 'insufficient_per_transfer_limits',
};

export const GENERIC_WARNINGS = {
  ALREADY_ISSUED: 'already_issued',
  INSUFFICIENT_FUNDS: 'insufficient_funds',
};

export const CONFIRMATION_WARNINGS = {
  ...GENERIC_WARNINGS,
  ...SPEND_LIMIT_WARNINGS,
};
