import PartnerSerializer from './-partner';

export default class PartnerKycSdkTokenSerializer extends PartnerSerializer {
  shouldIncludeLinkageData(relationshipKey) {
    /**
     * Taken from this list :
     * https://gitlab.qonto.co/back/partner-register/-/blob/master/app/serializers/client_api/kyc_sdk_token_serializer.rb
     **/
    return relationshipKey === 'stakeholder';
  }
}
