import { Factory } from 'miragejs';

export default Factory.extend({
  name: i => `Beneficiary ${i + 1}`,
  email: i => `beneficiary${i + 1}@acme.com`,
  afterCreate(beneficiary) {
    let isFx = Boolean(beneficiary.fx);
    beneficiary.update({
      sct: !isFx,
      fx: isFx,
    });
  },
});
