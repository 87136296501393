import ApplicationSerializer from './application';
import { adjustUser } from './user';

export default ApplicationSerializer.extend({
  include: ['user'],
  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);
    adjustUser(json.users[0]);

    return {
      device_remembered: json.mirage_session.device_remembered,
      expires: json.mirage_session.expires,
      websocket_token: json.mirage_session.websocket_token,
      visit_token: json.mirage_session.visit_token,
      user: json.users[0],
    };
  },
});
