/*
 * This file was copied from https://github.com/emberjs/ember-string/blob/master/addon/index.ts
 * KEEP AS-IS
 */

export function w(str) {
  return str.split(/\s+/);
}

export function decamelize(str) {
  return str.replace(/([a-z\d])([A-Z])/g, '$1_$2').toLowerCase();
}

export function dasherize(str) {
  return decamelize(str).replace(/[ _]/g, '-');
}

export function camelize(str) {
  return str
    .replace(/(-|_|\.|\s)+(.)?/g, (_match, _separator, chr) => (chr ? chr.toUpperCase() : ''))
    .replace(/(^|\/)([A-Z])/g, match => match.toLowerCase());
}

export function classify(str) {
  let parts = str.split('/');

  for (let i = 0; i < parts.length; i++) {
    parts[i] = parts[i]
      .replace(/^([-_])+(.)?/, (_match, _separator, chr) => (chr ? `_${chr.toUpperCase()}` : ''))
      .replace(
        /(.)(-|_|\.|\s)+(.)?/g,
        (_match, initialChar, _separator, chr) => initialChar + (chr ? chr.toUpperCase() : '')
      );
  }

  return parts.join('/').replace(/(^|\/|\.)([a-z])/g, match => match.toUpperCase());
}

export function underscore(str) {
  return str
    .replace(/([a-z\d])([A-Z]+)/g, '$1_$2')
    .replace(/-|\s+/g, '_')
    .toLowerCase();
}

export function capitalize(str) {
  return str.replace(/(^|\/)([a-z\u00C0-\u024F])/g, match => match.toUpperCase());
}
