import { Factory } from 'miragejs';

export default Factory.extend({
  firstName: 'Jim',
  lastName: 'Morrison',
  email: i => `jim.morrison+${i}@qonto.com`,
  owner: true,
  dateOfBirth: '1974-02-26',
  countryOfBirth: 'FR',
  countryOfResidence: 'FR',
  kycProviderName: 'fourthline',
  usTaxPayer: null,
  taxPayerOutsideLegalCountry: null,
});
