import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);

    delete json.role.id;
    delete json.role.membership_id;

    return json;
  },
});
