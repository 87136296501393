import Serializer from './application';

export default Serializer.extend({
  serialize(_, request) {
    let json = Serializer.prototype.serialize.apply(this, arguments);
    let country = request.queryParams.country?.toUpperCase() ?? 'FR';
    let { schema } = this.registry;

    let companies = json.companies.map(company => {
      // the first office in the list will be implicitly considered the
      // headquarter in order to simplify the implementation
      let headquarterId = company.office_ids[0];
      let headquarter = schema.offices.find(headquarterId);

      let serializedCompany = {
        name: headquarter.name,
        commercial_name: headquarter.name,
        legal_number: headquarter.legalNumber,
        company_info_provider_id: headquarter.companyInfoProviderId,
        address_line_1: headquarter.addressLine,
        address_zipcode: headquarter.zipcode,
        address_city: headquarter.city,
        country_code: headquarter.country,
        ...(country === 'IT' && {
          commercial_name: '',
        }),
        registered_offices:
          // Only FR companies have multiple offices
          country === 'FR'
            ? company.office_ids.map((officeId, index) => {
                let resource = schema.offices.find(officeId);
                let [hash] = this.getHashForIncludedResource(resource);
                let office = hash.offices[0];
                return {
                  address_city: office.city,
                  address_line_1: office.address_line,
                  address_zipcode: office.zipcode,
                  commercial_name: office.name,
                  company_info_provider_id: office.company_info_provider_id,
                  country_code: office.country,
                  headquarter: index === 0,
                  legal_number: office.legal_number, // FR Siret
                  name: office.name,
                };
              })
            : [],
      };

      return serializedCompany;
    });

    return companies;
  },
});
