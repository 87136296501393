import { pluralize } from 'ember-inflector';
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class VerificationKycSerializer extends JSONAPISerializer {
  keyForAttribute(attr) {
    // use snake_case for attribute names
    return underscore(attr);
  }

  typeKeyForModel({ modelName }) {
    // remove `verification-` prefix from model names
    if (modelName.startsWith('verification-')) {
      modelName = modelName.slice(13);
    }

    return underscore(pluralize(modelName));
  }

  serialize(object) {
    delete object.attrs.redoToken;
    return super.serialize(...arguments);
  }
}
