import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  keyForModel() {
    return 'membership_bank_details';
  },

  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);

    if (json.membership_bank_details) {
      this._adjust(json.membership_bank_details);
    }

    return json;
  },

  _adjust(membershipBankDetails) {
    delete membershipBankDetails.membership_id;
  },
});
