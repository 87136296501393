import { pluralize } from 'ember-inflector';
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default JSONAPISerializer.extend({
  shouldIncludeLinkageData(relationshipKey) {
    return relationshipKey !== 'user';
  },

  keyForAttribute(attr) {
    // use snake_case for attribute names
    return underscore(attr);
  },

  keyForRelationship(key) {
    // use snake_case for relationship names
    return underscore(key);
  },

  typeKeyForModel({ modelName }) {
    // remove `cc-` prefix from model names
    if (modelName.startsWith('cc-')) {
      modelName = modelName.slice(3);
    }

    return underscore(pluralize(modelName));
  },
});
