export const STATUS = {
  SCHEDULED: 'scheduled',
  ACTIVE: 'active',
  CANCELED: 'canceled',
  FINISHED: 'finished',
  SUSPENDED: 'suspended',
};

export const FREQUENCY = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly',
};
