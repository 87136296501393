import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  keyForModel: () => 'settings',

  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);
    delete json.settings.id;
    delete json.settings.organization_id;
    return json;
  },
});
