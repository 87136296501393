import { Factory } from 'miragejs';

import { USER_ACTIONS_STATUS, USER_ACTIONS_TYPE } from '@qonto/mirage/constants/user-action';
import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  type: USER_ACTIONS_TYPE.SETUP,
  status: USER_ACTIONS_STATUS.ENABLED,

  afterCreate(userAction) {
    assert(userAction.name, `Missing 'name' on 'user-action:${userAction.id}'`);
    assert(
      userAction.membership,
      `Missing 'membership' relationship on 'user-action:${userAction.id}'`
    );
  },
});
