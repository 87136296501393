import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  serialize(integration) {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);

    if (json.integration) {
      // Remove the `id` property from each serialized integration to match the backend API response
      delete json.integration.id;

      // Expose the organization slug as the `login` property to match the backend API response
      json.integration.login = integration.organization.slug;
    }

    return json;
  },
});
