import { pluralize } from 'ember-inflector';

import { underscore } from '@qonto/mirage/utils/string';

import ApplicationSerializer from './application';

export default class OrgnizationKybDetailSerializer extends ApplicationSerializer {
  keyForRelationship(modelName) {
    return underscore(pluralize(modelName));
  }
}
