import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  amount: {
    value: '100',
    currency: 'EUR',
  },
  canceledAt: null,
  declineReason: null,
  executionDate: '2024-07-29T14:12:51.874Z',
  nrc: '12345678901234567890',
  status: 'scheduled',
  statusGroup: 'processing',
  taxCode: '200',
  receiptUrl: 'https://example.com/proof.pdf',
  taxDescription: 'Declaración Informativa. Declaración anual de operaciones con terceras personas',

  afterCreate(model) {
    assert(model.bankAccount, `Missing 'bankAccount' relationship on 'nrc-payment:${model.id}'`);
    assert(model.organization, `Missing 'organization' relationship on 'nrc-payment:${model.id}'`);
  },
});
