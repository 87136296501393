import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  getHashForResource() {
    let [hash, addToIncludes] = ApplicationSerializer.prototype.getHashForResource.apply(
      this,
      arguments
    );

    if (Array.isArray(hash)) {
      for (let transfer of hash) {
        this._adjust(transfer);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(transfer) {
    transfer.amount_cents = Math.round(transfer.amount * 100);
    delete transfer.id;
  },
});
