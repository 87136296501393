import { Factory, trait } from 'miragejs';

import {
  ADDON_ACCOUNT_PAYABLE,
  ADDON_ACCOUNT_RECEIVABLE,
  ADDON_EXPENSE_AND_SPEND_MANAGEMENT,
  PRICE_PLAN_SOLO_BASIC,
  PRICE_PLAN_SOLO_PREMIUM,
  PRICE_PLAN_SOLO_SMART,
  PRICE_PLAN_TEAM_BUSINESS,
  PRICE_PLAN_TEAM_ENTERPRISE,
  PRICE_PLAN_TEAM_ESSENTIAL,
} from '@qonto/mirage/utils/product-offers';

export default Factory.extend({
  PRICE_PLAN_SOLO_BASIC: trait(PRICE_PLAN_SOLO_BASIC),
  PRICE_PLAN_SOLO_PREMIUM: trait(PRICE_PLAN_SOLO_PREMIUM),
  PRICE_PLAN_SOLO_SMART: trait(PRICE_PLAN_SOLO_SMART),
  PRICE_PLAN_TEAM_BUSINESS: trait(PRICE_PLAN_TEAM_BUSINESS),
  PRICE_PLAN_TEAM_ENTERPRISE: trait(PRICE_PLAN_TEAM_ENTERPRISE),
  PRICE_PLAN_TEAM_ESSENTIAL: trait(PRICE_PLAN_TEAM_ESSENTIAL),

  ADDON_EXPENSE_AND_SPEND_MANAGEMENT: trait(ADDON_EXPENSE_AND_SPEND_MANAGEMENT),
  ADDON_ACCOUNT_PAYABLE: trait(ADDON_ACCOUNT_PAYABLE),
  ADDON_ACCOUNT_RECEIVABLE: trait(ADDON_ACCOUNT_RECEIVABLE),
});
