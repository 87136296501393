import { ActiveModelSerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class ReceivableInvoicesSharedReceivableInvoicesSerializer extends ActiveModelSerializer {
  include = ['customer', 'bankAccount', 'organization'];
  embed = true;
  serializeIds = 'never';

  keyForAttribute(attr) {
    return underscore(attr);
  }

  serializerFor(type) {
    return super.serializerFor(`receivable-invoices/shared/${type}`);
  }

  serialize(object) {
    let { receivable_invoice: attributes } = super.serialize(...arguments);
    let { bank_account: bankAccount, beneficiary_name: beneficiaryName, id } = attributes;

    bankAccount.beneficiary_name = beneficiaryName;
    bankAccount.type = 'transfer';
    attributes.payment_methods = [bankAccount];
    if (!attributes.contact_email) {
      attributes.contact_email = object.organization.contactEmail;
    }
    attributes.pdf_url = attributes.url;
    delete attributes.id;
    delete attributes.bank_account;
    delete attributes.beneficiary_name;
    delete attributes.url;
    return {
      data: {
        id,
        type: 'receivable_invoices',
        attributes,
      },
    };
  }
}
