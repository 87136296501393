import Serializer from './application';

export default class extends Serializer {
  serializeIds = 'always';

  serialize(object, request) {
    let json = super.serialize(object, request);
    this.alwaysHaveUpdatedById(json);
    return json;
  }

  alwaysHaveUpdatedById(json) {
    let updateUpdatedById = approvalWorkflow =>
      (approvalWorkflow.updated_by_id = approvalWorkflow.updated_by_id ?? null);
    json.approval_workflows?.forEach(updateUpdatedById);
    if (json.approval_workflow) updateUpdatedById(json.approval_workflow);
  }
}
