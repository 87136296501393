export let STATUS = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  REVOKED: 'revoked',
  INVITABLE: 'invitable',
  IGNORED: 'ignored',
  DELETED: 'deleted',
  INVITED: 'invited',
};

export let KYC_STATUS = {
  PENDING: 'pending',
  REVIEWABLE: 'reviewable',
  SUBMITTED: 'submitted',
  ACCEPTED: 'accepted',
  REFUSED: 'refused',
  WAITING_DOCUMENT: 'waiting_document',
  SUBMISSION_ERROR: 'submission_error',
  KYC_NOT_APPLICABLE: 'kyc_not_applicable',
  PROCESSING: 'processing',
};
