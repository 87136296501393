import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  serialize(object, request) {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);

    // we are customizing the response here because we always return one element (BE rules)
    if (request.method === 'GET') {
      if (json.allowed_emails.length) {
        json.allowed_emails = [json.allowed_emails[0]];
      }
    }

    if (json.allowed_email) {
      delete json.allowed_email.sequential_id;
    } else if (json.allowed_emails) {
      for (let allowedEmail of json.allowed_emails) {
        delete allowedEmail.sequential_id;
      }
    }

    return json;
  },
});
