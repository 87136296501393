import Serializer from './application';

export default Serializer.extend({
  getHashForResource() {
    let [hash, addToIncludes] = Serializer.prototype.getHashForResource.apply(this, arguments);

    if (Array.isArray(hash)) {
      for (let child of hash) {
        this._adjust(child);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(beneficiary) {
    let { schema } = this.registry;

    let bankAccount = schema.bankAccounts.find(beneficiary.bank_account_id);
    beneficiary.organization_id = bankAccount.organizationId;

    let beneficiaries = schema.beneficiaries.where({
      organizationId: bankAccount.organizationId,
    });

    delete beneficiary.beneficiary_ids;
    beneficiary.beneficiaries = [];
    beneficiaries.models.forEach(b => {
      beneficiary.beneficiaries.push({
        bulk_id: b.bulkId,
        name: b.name,
        email: b.email,
        bic: b.bic,
        iban: b.iban,
        activity_tag: null,
        selected: true,
      });
    });

    return beneficiaries;
  },
});
