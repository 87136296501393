import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  sequentialId: i => i + 1,

  amount: 20000,
  amountCurrency: 'EUR',
  localAmount: 21259,
  localAmountCurrency: 'CHF',
  exchangeRate: 0.2,
  reference: 'Foobar',
  emitterAccountIdentifier: 'CH9506744000000000268',
  emitterBankIdentifier: 'CRESCHZZXXX',
  emitterNameAddress: '36114-3881 West Salfort',

  afterCreate(model) {
    assert(model.bankAccount, `Missing 'bankAccount' relationship on 'swift-income:${model.id}'`);

    if (!model.slug) {
      let orgSlug = model.bankAccount.organization.slug;
      let slug = `${orgSlug || ''}-swift-income-${model.sequentialId}`;
      model.update({ slug });
    }

    assert(
      !model.organization || !model.organizationId,
      `You should not pass an 'organization' relationship on 'swift-income:${model.id}'`
    );
  },
});
