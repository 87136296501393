import Serializer from './application';

export default class extends Serializer {
  serialize(object, request) {
    let { schema } = this.registry;
    let json = super.serialize(object, request);

    if (object.stepIds?.length > 0) {
      let steps = schema.approvalWorkflowStateSteps.find(object.stepIds);

      steps = steps.models.map(function (step) {
        let approvers = schema.memberships.find(step.approverIds).models;
        let value = schema.memberships.find(step.valueIds).models;

        return {
          operation: step.operation,
          status: step.status,
          approvers: step.approverIds.map(id => {
            let approver = approvers.find(approver => approver.id === id);
            let approverUser = schema.users.find(approver.userId);
            return {
              id,
              full_name: `${approverUser.firstName} ${approverUser.lastName}`,
            };
          }),
          value: step.valueIds.map(id => {
            let val = value.find(val => val.id === id);
            let valUser = schema.users.find(val.userId);
            return {
              id,
              full_name: `${valUser.firstName} ${valUser.lastName}`,
            };
          }),
        };
      });

      delete json.approval_workflow_state.step_ids;
      json.approval_workflow_state.steps = steps;
    }

    return json;
  }
}
