import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  usTaxPayer: null,
  taxPayerOutsideLegalCountry: null,

  afterCreate(model) {
    assert(
      model.membership,
      `Missing 'membership' relationship on 'membership-kyb-organization:${model.id}'`
    );
  },
});
