import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  amount: 42.31,
  amountCurrency: 'EUR',
  attachmentRequested: false,
  attachmentRequestedAt: null,
  attachmentSuggestionIds: () => [],
  automaticallyAddedAttachmentIds: () => [],
  counterpartyName: 'Gleichner and Sons',
  declinedReason: null,
  description: 'Dare, Greenfelder and Parker',
  displayAt: '2018-05-10T13:45:46.689Z',
  emittedAt: '2018-06-17T07:38:54.000Z',
  enrichmentData: () => {},
  note: null,
  operationMethod: null,
  operationType: null,
  qualifiedForAccounting: false,
  reported: false,
  sequentialId: i => i + 1,
  settledAt: '2018-05-10T13:45:46.689Z',
  side: null,
  status: 'completed',
  vatAmount: null,
  vatCountry: null,
  vatStatus: null,
  vat: null,
  cardTransactionStatus: null,

  afterCreate(transaction) {
    assert(
      transaction.bankAccount,
      `Missing 'bankAccount' relationship on 'transaction:${transaction.id}'`
    );

    if (transaction.bankAccount?.coreBankingSystem === 'connect') {
      if (transaction.operationMethod === 'card')
        transaction.update({
          counterparty: null,
        });
      else {
        transaction.update({
          counterparty: {
            iban: 'FR7630004021180001013711792',
            bic: 'BNPAFRPPXXX',
            account_number: 'ABC1234',
          },
        });
      }

      transaction.update({ activityTag: 'finance', side: 'debit' });
    } else {
      assert(
        transaction.subject,
        `Missing 'subject' relationship on 'transaction:${transaction.id}'`
      );

      // For now we only infer the initiator from the following subjects, since these are the only subjects that have an initiator
      if (['card', 'check', 'transfer'].includes(transaction.subject.modelName)) {
        assert(
          !transaction.initiator && !transaction.initiatorId,
          `You should not pass an 'initiator' relationship on 'transaction:${transaction.id}'. This needs to be set on the subject.`
        );
      }

      if ('initiator' in transaction.subject) {
        transaction.update({ initiator: transaction.subject.initiator });
      }

      if ('holder' in transaction.subject) {
        transaction.update({ initiator: transaction.subject.holder });
      }

      if (!transaction.operationMethod) {
        let operationMethod;
        let subjectType = transaction.subject.modelName;
        if (
          subjectType === 'transfer' ||
          subjectType === 'income' ||
          subjectType === 'swift-income'
        ) {
          operationMethod = 'transfer';
        } else if (subjectType === 'card') {
          operationMethod = 'card';
        } else if (subjectType === 'check') {
          operationMethod = 'cheque';
        } else if (subjectType === 'direct-debit' || subjectType === 'direct-debit-collection') {
          operationMethod = 'direct_debit';
        } else if (subjectType === 'wallet-to-wallet') {
          operationMethod = 'biller';
        } else if (subjectType === 'pagopa-payment') {
          operationMethod = 'pagopa_payment';
        } else if (subjectType === 'f24-payment') {
          operationMethod = 'tax';
        } else if (subjectType === 'financing-income' || subjectType === 'financing-installment') {
          operationMethod = 'pay_later';
        } else {
          assert(false, `Unknown subject type on \`transaction:${transaction.id}\``);
        }

        transaction.update({ operationMethod });
      }

      if (!transaction.operationType) {
        let operationType;
        let subjectType = transaction.subject.modelName;
        if (subjectType === 'transfer') {
          operationType = 'transfer';
        } else if (subjectType === 'income') {
          operationType = 'income';
        } else if (subjectType === 'swift-income') {
          operationType = 'swift_income';
        } else if (subjectType === 'card') {
          operationType = 'card';
        } else if (subjectType === 'check') {
          operationType = 'cheque';
        } else if (subjectType === 'direct-debit') {
          operationType = 'direct_debit';
        } else if (subjectType === 'wallet-to-wallet') {
          operationType = 'biller';
        } else if (subjectType === 'pagopa-payment') {
          operationType = 'pagopa_payment';
        } else if (subjectType === 'f24-payment') {
          operationType = 'f24';
        } else if (subjectType === 'financing-income') {
          operationType = 'pay_later';
        } else if (subjectType === 'financing-installment') {
          operationType = 'financing_installment';
        } else if (subjectType === 'direct-debit-collection') {
          operationType = 'direct_debit_collection';
        } else {
          assert(false, `Unknown subject type on \`transaction:${transaction.id}\``);
        }

        transaction.update({ operationType });
      }

      // Using hasOwnProperty allows to set it as `null` or `undefined`
      if (!transaction.hasOwnProperty('activityTag')) {
        let activityTag;
        let subjectType = transaction.subject.modelName;
        if (subjectType === 'pagopa-payment') {
          activityTag = 'other_expense';
        } else if (subjectType === 'f24-payment') {
          activityTag = 'tax';
        } else {
          activityTag = 'finance';
        }

        transaction.update({ activityTag });
      }

      if (!transaction.side) {
        let subjectType = transaction.subject.modelName;
        let isCreditSubject = [
          'income',
          'swift-income',
          'check',
          'financing-income',
          'direct-debit-collection',
        ].includes(subjectType);
        let side = isCreditSubject ? 'credit' : 'debit';

        transaction.update({ side });
      }
    }

    if (!transaction.slug) {
      let orgSlug = transaction.bankAccount?.organization?.slug;
      let slug = (orgSlug || '') + (orgSlug ? '-' : '') + `card-${transaction.sequentialId}`;
      transaction.update({ slug });
    }
  },
});
