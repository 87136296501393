import Serializer from './application';

export default Serializer.extend({
  getHashForResource() {
    let [hash, addToIncludes] = Serializer.prototype.getHashForResource.apply(this, arguments);

    if (Array.isArray(hash)) {
      for (let child of hash) {
        this._adjust(child);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(document) {
    if (document.subject_type === 'register-organization') {
      document.subject_type = 'Organization';
    } else if (document.subject_type === 'stakeholder') {
      document.subject_type = 'Stakeholder';
    }
  },
});
