import { Factory } from 'miragejs';

export default Factory.extend({
  fileName: 'foobaz.png',
  fileSize: 1024,
  fileUrl: 'https://example.com/foobaz.png',
  fileContentType: 'image/png',
  downloadUrl: 'https://example.com/foobaz.pdf',
  status: 'pending',
});
