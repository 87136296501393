import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class TableBankAccountSerializer extends JSONAPISerializer {
  alwaysIncludeLinkageData = false;
  attrs = ['bic', 'iban', 'name'];

  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(attr) {
    return underscore(attr);
  }

  typeKeyForModel({ modelName }) {
    return underscore(modelName);
  }
}
