export default [
  /**
   * Predefined columns
   */
  {
    type: 'predefined',
    id: 'status',
    label: 'Status',
  },
  {
    type: 'predefined',
    id: 'settlement_date',
    label: 'Settlement date',
  },
  {
    type: 'predefined',
    id: 'settlement_date_local',
    label: 'Settlement date (local)',
  },
  {
    type: 'predefined',
    id: 'settlement_date_utc',
    label: 'Settlement date (UTC)',
  },
  {
    type: 'predefined',
    id: 'value_date_utc',
    label: 'Operation date (UTC)',
  },
  {
    type: 'predefined',
    id: 'value_date_local',
    label: 'Operation date (local)',
  },
  {
    type: 'predefined',
    id: 'amount',
    label: 'Amount',
  },
  {
    type: 'predefined',
    id: 'debit',
    label: 'Debit',
  },
  {
    type: 'predefined',
    id: 'credit',
    label: 'Credit',
  },
  {
    type: 'predefined',
    id: 'balance',
    label: 'Balance',
  },
  {
    type: 'predefined',
    id: 'amount_currency',
    label: 'Amount currency',
  },
  {
    type: 'predefined',
    id: 'local_amount',
    label: 'Local amount',
  },
  {
    type: 'predefined',
    id: 'local_amount_currency',
    label: 'Local amount currency',
  },
  {
    type: 'predefined',
    id: 'account_name',
    label: 'Account name',
  },
  {
    type: 'predefined',
    id: 'iban',
    label: 'IBAN',
  },
  {
    type: 'predefined',
    id: 'counterparty_name',
    label: 'Counterparty name',
  },
  {
    type: 'predefined',
    id: 'counterparty_iban',
    label: 'Counterparty IBAN',
  },
  {
    type: 'predefined',
    id: 'vat_amount',
    label: 'VAT amount',
  },
  {
    type: 'predefined',
    id: 'vat_country',
    label: 'VAT country',
  },
  {
    type: 'predefined',
    id: 'transaction_method',
    label: 'Transaction method',
  },
  {
    type: 'predefined',
    id: 'card_name',
    label: 'Card name',
  },
  {
    type: 'predefined',
    id: 'transaction_initiator',
    label: 'Transaction initiator',
  },
  {
    type: 'predefined',
    id: 'team',
    label: 'Team',
  },
  {
    type: 'predefined',
    id: 'attachments',
    label: 'Attachments',
  },
  {
    type: 'predefined',
    id: 'attachment_required',
    label: 'Attachment required',
  },
  {
    type: 'predefined',
    id: 'attachment_lost',
    label: 'Attachment lost',
  },
  {
    type: 'predefined',
    id: 'transaction_reference',
    label: 'Transaction reference',
  },
  {
    type: 'predefined',
    id: 'activity',
    label: 'Activity',
  },
  {
    type: 'predefined',
    id: 'comment',
    label: 'Comment',
  },
  {
    type: 'predefined',
    id: 'note',
    label: 'Note',
  },
  {
    type: 'predefined',
    id: 'description',
    label: 'Description',
  },

  /**
   * Label List columns
   */

  {
    type: 'label_list',
    id: 'foo',
    label: 'Foo',
  },
  {
    type: 'label_list',
    id: 'bar',
    label: 'Bar',
  },
  {
    type: 'label_list',
    id: 'baz',
    label: 'Baz',
  },
];
