/* eslint-disable camelcase */
import ApplicationSerializer from './application';

export default class suggestedInvitationSerializer extends ApplicationSerializer {
  serialize() {
    let json = super.serialize(...arguments);

    let { suggested_invitation, suggested_invitations } = json;

    if (suggested_invitation) {
      this._adjust(suggested_invitation);
    }

    if (suggested_invitations?.length) {
      suggested_invitations.forEach(suggestedInvitation => {
        this._adjust(suggestedInvitation);
      });
    }

    return json;
  }

  _adjust(suggestedInvitation) {
    delete suggestedInvitation.organization_id;
  }
}
