import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  eInvoicingActivated: true,
  eInvoicingTermsAndConditionsAccepted: true,
  fiscalCode: 'RRSMR00F22D00TAZ',
  provinceCode: 'AG',
  taxRegime: 'RF01',
  legalTvaNumber: 'IT123456789',

  id() {
    return this.organization.id;
  },

  afterCreate(eInvoiceActivation) {
    assert(eInvoiceActivation.organization, 'eInvoiceActivation.organization must be assigned');

    assert(
      eInvoiceActivation.organization.legalCountry === 'IT',
      'eInvoiceActivation can only belong to an italian organization'
    );

    assert(
      eInvoiceActivation.eInvoicingActivated ||
        !eInvoiceActivation.eInvoicingTermsAndConditionsAccepted,
      "eInvoicingActivated can't be true, when eInvoicingTermsAndConditionsAccepted is false"
    );
  },
});
