import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default JSONAPISerializer.extend({
  alwaysIncludeLinkageData: true,

  keyForAttribute(attr) {
    // use snake_case for attribute names
    return underscore(attr);
  },

  getResourceObjectForModel() {
    let hash = JSONAPISerializer.prototype.getResourceObjectForModel.apply(this, arguments);

    hash.attributes.url = `https://bucket.s3-aws-region.amazonaws.com/files/${hash.id}/${hash.attributes.filename}`;

    return hash;
  },
});
