import { belongsTo, Model } from 'miragejs';

export default Model.extend({
  // ignore `document_ids` attribute for now
  documents: null,
  // ignore `subscription_ids` attribute for now
  subscriptions: null,

  address: belongsTo(),
  organization: belongsTo(),
  team: belongsTo(),
  user: belongsTo(),
  spendLimit: belongsTo(),
  bankDetail: belongsTo(),

  kycKybUpdateMembershipChangeRequest: belongsTo(),
  membershipKybDetails: belongsTo(),
});
