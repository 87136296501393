import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);
    delete json.remuneration_details.remunerated_account_id;
    json.remuneration_details.account_id = json.remuneration_details.bank_account_id;
    delete json.remuneration_details.bank_account_id;
    return json;
  },
  keyForModel() {
    return 'remuneration_details';
  },
});
