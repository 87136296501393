import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  keyForModel() {
    return 'rule';
  },
  keyForCollection() {
    return 'rules';
  },
});
