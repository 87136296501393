import { Factory } from 'miragejs';

export default Factory.extend({
  code: () => generateCode(),
  email: null,
  firstName: null,
  lastName: null,
  locale: null,
});

function generateCode() {
  return Math.random().toString().slice(2, 8);
}
