import Serializer from './application';

export default Serializer.extend({
  getHashForResource() {
    let [hash, addToIncludes] = Serializer.prototype.getHashForResource.apply(this, arguments);

    if (Array.isArray(hash)) {
      for (let subscription of hash) {
        this._adjust(subscription);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(subscription) {
    delete subscription.sequential_id;

    subscription.devices = {
      email: subscription.devices_email,
      push: subscription.devices_push,
    };

    delete subscription.devices_email;
    delete subscription.devices_push;
  },
});
