import AttachmentFactory from './attachment';

export default AttachmentFactory.extend({
  afterCreate(attachment, server) {
    let [afterCreate] = AttachmentFactory.extractAfterCreateCallbacks();
    afterCreate(attachment, server);

    let {
      probativeAttachmentStatus: status,
      probativeAttachmentFileName: fileName,
      probativeAttachmentFileUrl: fileUrl,
      probativeAttachmentFileSize: fileSize,
    } = attachment;

    let probativeAttachment = server.create('probative-attachment', {
      status,
      fileName,
      fileUrl,
      fileSize,
    });

    attachment.update({ probativeAttachment });
  },
});
