import { Factory } from 'miragejs';

export default Factory.extend({
  activityTag: 'direct_debit_hold',
  amount: {
    value: '15.23',
    currency: 'EUR',
  },
  executionDate: '2024-06-08',
  releaseDate: '2024-06-10',
  isReleased: true,
  guardingRate: '0.3',
});
