import { Factory } from 'miragejs';

export default Factory.extend({
  name: 'My Custom Template',
  type: 'custom', // or 'default
  format: 'csv',
  separator: ',',
  export_full_data_set: false,
  creator_name: 'Michael Scott',
  created_at() {
    return new Date().toISOString();
  },
  updated_at() {
    return new Date().toISOString();
  },
  columns: [],
});
