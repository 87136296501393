import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  activityTag: 'atm',
  bic: 'TRZOFR21XYZ',
  enrichmentData: () => {},
  iban: 'FR7630004029990001013084373',
  instant: false,
  reference: i => `Reference ${i + 1}`,
  sequentialId: i => i + 1,
  status: 'pending',

  localAmount() {
    return this.amount;
  },

  localAmountCurrency() {
    return this.amountCurrency;
  },

  afterCreate(transfer) {
    assert(transfer.bankAccount, `Missing 'bankAccount' relationship on 'transfer:${transfer.id}'`);
    assert(transfer.initiator, `Missing 'initiator' relationship on 'transfer:${transfer.id}'`);
    assert(
      !transfer.organization || !transfer.organizationId,
      `You should not pass an 'organization' relationship on 'transfer:${transfer.id}'`
    );

    if (!transfer.slug) {
      let slug = `${transfer.bankAccount.organization.slug}-transfer-${transfer.sequentialId}`;
      transfer.update({ slug });
    }
  },
});
