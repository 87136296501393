export const BANK_CONNECTION_STATUS = {
  SYNC_IN_PROGRESS: 'SYNC_IN_PROGRESS',
  SYNCHRONIZED: 'SYNCHRONIZED',
  SUSPENDED: 'SUSPENDED',
  PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
  PASSWORD_UPDATED: 'PASSWORD_UPDATED',
  ACTION_REQUIRED_ON_BANK: 'ACTION_REQUIRED_ON_BANK',
  PROVIDER_ISSUE: 'PROVIDER_ISSUE',
};

export const BANK_CONNECTION_PROVIDER = {
  POWENS: 'POWENS',
  FINAPI: 'FINAPI',
};
