import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  name: null,
  matchesCount: 0,
  position: null,
  createdAt: () => new Date('2021-10-12T09:30:00.654Z'),
  type: null,
  query: () => ({}),

  afterCreate(searchPreset) {
    assert(
      searchPreset.organization,
      `Missing 'organization' relationship on 'search-preset:${searchPreset.id}'`
    );

    assert(
      searchPreset.membership,
      `Missing 'membership' relationship on 'search-preset:${searchPreset.id}'`
    );
  },
});
