import { Factory } from 'miragejs';

export default Factory.extend({
  contactName: null,
  firstLine: '41015 Alvaro Row',
  secondLine: '479',
  thirdLine: 'Suite 691',
  zipcode: '30520-0712',
  city: 'Deloisburgh',
  country: 'HN',
});
