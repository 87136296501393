import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

function isObject(obj) {
  return typeof obj === 'object' && obj !== null;
}

function isAmount(obj) {
  if (!obj) return true;
  return isObject(obj) && isValidString(obj.value) && isValidString(obj.currency);
}

function isValidString(primitive) {
  return typeof primitive === 'string' || primitive === null || primitive === undefined;
}

function isValidRate(primitive) {
  return typeof primitive === 'number' || primitive === null || primitive === undefined;
}

function isValidAmount(primitive) {
  return isAmount(primitive) || primitive === null || primitive === undefined;
}

export default Factory.extend({
  amount: null,
  rate: null,
  amountExcludingVat: null,

  afterCreate(vat) {
    assert(vat.transactionId, `Missing 'transaction' relationship on 'vat: ${vat.id}'`);
    assert(isValidAmount(vat.amount), `'amount' is expected to be an amount on 'vat:${vat.id}'`);
    assert(
      isValidAmount(vat.amountExcludingVat),
      `'amountExcludingVat' is expected to be an amount on 'vat:${vat.id}`
    );
    assert(isValidRate(vat.rate), `'rate' is expected to be a number on 'vat:${vat.id}'`);
  },
});
