import { Factory } from 'miragejs';

export default Factory.extend({
  creditNoteNextNumber: '001',
  creditNoteNumberingPattern: 'CN-(YYYY)-',
  invoiceNextNumber: '001',
  invoiceNumberingPattern: 'INV-(YYYY)-',
  quoteNextNumber: '001',
  quoteNumberingPattern: 'QT-(YYYY)-',
  numberingMode: 'automatic',

  afterCreate(model) {
    let year = new Date().getFullYear();
    model.update({
      creditNoteNextNumberFormatted: model.creditNoteNextNumberFormatted || `CN-${year}-001`,
      invoiceNextNumberFormatted: model.invoiceNextNumberFormatted || `INV-${year}-001`,
      quoteNextNumberFormatted: model.quoteNextNumberFormatted || `QT-${year}-001`,
      selfInvoiceNextNumberFormatted: model.selfInvoiceNextNumberFormatted || `AF-${year}-001`,
    });
  },
});
