import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  include(request) {
    let includes = [];
    let includesQP = request.queryParams.includes || [];

    if (includesQP.includes('memberships')) {
      includes.push('initiator', 'approver');
    }

    return includes;
  },

  keyForModel() {
    return 'request_mileage';
  },
});
