import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  include(request) {
    let includes = [];
    let includesQP = request.queryParams.includes || [];

    if (includesQP.includes('memberships')) {
      includes.push('initiator', 'approver');
    }

    return includes;
  },

  keyForModel() {
    return 'request_transfer';
  },

  getHashForResource() {
    let [hash, addToIncludes] = ApplicationSerializer.prototype.getHashForResource.apply(
      this,
      arguments
    );

    if (Array.isArray(hash)) {
      for (let request of hash) {
        this._adjust(request);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(request) {
    let { schema } = this.registry;

    let bankAccount = schema.bankAccounts.find(request.bank_account_id);
    request.organization_id = bankAccount.organizationId;
  },
});
