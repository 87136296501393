export default [
  {
    name: 'Slack',
    id: '4ab1559c-1dfd-4798-928a-de6b6be92a00',
    slug: 'slack',
    application_type: 'notifier',
    created_at: '2020-06-17T15:30:57.536Z',
    params: {
      auth_schema: 'oauth2',
      auth_url: 'https://slack.com/oauth/v2/authorize',
    },
  },
];
