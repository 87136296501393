import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  birthCity: 'Berlin',
  birthCountry: 'DE',
  birthDate: '2000-08-17',
  firstName: 'John',
  lastName: 'Doe',
  nationality: 'DE',
  email: 'john.doe@example.com',

  afterCreate(model) {
    assert(model.creation, `Missing 'creation' relationship on 'cc-physical-entity:${model.id}'`);
  },
});
