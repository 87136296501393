import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  requestType: 'transfer',
  createdBy: null,
  updatedBy: null,
  updatedAt: null,
  isValid: true,
  isDefault: false,
  workflowErrors: null,
  rulesets: [
    {
      id: '14269bea-d1b1-41cf-96c5-90983fefa5ff',
      conditions: [
        {
          field: 'amount',
          operation: 'gte',
          value: {
            value: '0.00',
            currency: 'EUR',
          },
        },
      ],
      steps: [
        {
          id: '91d12b85-b5b8-4b82-a6c4-5d4bfaed3a30',
          field: 'role',
          operation: 'one_of',
          value: 'manager',
          order: 1,
        },
        {
          id: '443a8d01-714b-4ee3-923a-f76f5d677a6f',
          field: 'role',
          operation: 'one_of',
          value: 'admin',
          order: 2,
        },
      ],
    },
  ],
  afterCreate(approvalWorkflow) {
    assert(
      approvalWorkflow.organization,
      `Missing 'organization' relationship on 'approval-workflow:${approvalWorkflow.id}'`
    );
    assert(
      approvalWorkflow.createdBy,
      `Missing 'createdBy' relationship on 'approval-workflow:${approvalWorkflow.id}'`
    );
  },
});
