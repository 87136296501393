import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  contentType: 'application/pdf',
  docSubtype: null,
  docType: 'poi',
  name: i => `some_file_${i + 1}.pdf`,
  size: i => 1096884 - i * 13,
  url: i => `https://qonto-dev.s3.eu-central-1.amazonaws.com/documents/some_file_${i + 1}.pdf`,

  afterCreate(model) {
    assert(model.subject, `Missing 'subject' relationship on 'document:${model.id}'`);
  },
});
