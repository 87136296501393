import { pluralize } from 'ember-inflector';
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class PaymentIntentSerializer extends JSONAPISerializer {
  keyForAttribute(attr) {
    // use snake_case for attribute names
    return underscore(attr);
  }

  typeKeyForModel({ modelName }) {
    return underscore(pluralize(modelName));
  }
}
