import { belongsTo, hasMany, Model } from 'miragejs';

export default Model.extend({
  attachments: hasMany(),
  labels: hasMany(),
  initiator: belongsTo('membership'),
  subject: belongsTo({ polymorphic: true }),
  bankAccount: belongsTo(),
  period: belongsTo(),
});
