import { Factory } from 'miragejs';

import { SHAREHOLDER_STATUS } from '@qonto/mirage/constants/cc-shareholder';
import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  capitalAmount: '1000.00',
  capitalAmountCurrency: 'EUR',
  createdAt: '2021-08-17T12:31:32.689Z',
  status: SHAREHOLDER_STATUS.SUBMITTED,
  isOwner: false,

  afterCreate(model) {
    assert(model.creation, `Missing 'creation' relationship on 'cc-shareholder:${model.id}'`);

    if (model.status !== SHAREHOLDER_STATUS.DRAFT) {
      assert(model.entity, `Missing 'entity' relationship on 'cc-shareholder:${model.id}'`);
    }

    if (
      (!model.isOwner || model.entity.modelName !== 'cc-physical-entity') &&
      model.status !== SHAREHOLDER_STATUS.DRAFT
    ) {
      assert(
        model.capitalAmount !== null,
        `'capitalAmount' on 'cc-shareholder:${model.id}' cannot be null`
      );
    }

    if (model.capitalAmount !== null) {
      assert(
        typeof model.capitalAmount === 'string',
        `'capitalAmount' on 'cc-shareholder:${model.id}' must be a string`
      );
      assert(
        /\d+(\.\d+)?/.test(model.capitalAmount),
        `'capitalAmount' on 'cc-shareholder:${model.id}' must only contain digits and a decimal point`
      );
    }
  },
});
