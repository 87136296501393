import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  fileName: 'invoice123.pdf',
  invoiceNumber: 'INVOICE123',
  supplierName: 'Gleichner and Sons',
  description: 'Dare, Greenfelder and Parker',
  totalAmount: { value: '42.31', currency: 'EUR' },
  dueDate: '2022-01-30T07:38:54.000Z',
  paymentDate: '2022-01-30T14:38:54.000Z',
  scheduledDate: '2022-01-30T18:38:54.000Z',
  status: 'paid',
  iban: 'DE89 3704 0044 0532 0130 00',
  source: 'email_forward',
  createdAt: '2022-01-01T13:45:46.689Z',
  analyzedAt: null,
  hasDuplicates: false,
  requestTransfer: null,
  isAttachmentInvoice: true,
  isAttachmentNonFinancial: false,

  afterCreate(model) {
    assert(
      model.organization,
      `Missing 'organization' relationship on 'supplier-invoice:${model.id}'`
    );

    assert(model.attachment, `Missing 'attachment' relationship on 'supplier-invoice:${model.id}'`);
  },
});
