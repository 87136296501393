import Serializer from './application';

export default Serializer.extend({
  getHashForResource() {
    let [hash, addToIncludes] = Serializer.prototype.getHashForResource.apply(this, arguments);

    if (Array.isArray(hash)) {
      for (let attachment of hash) {
        this._adjust(attachment);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(attachment) {
    delete attachment.sequential_id;

    attachment.thumbnail = { file_url: attachment.thumbnail_url };
    delete attachment.thumbnail_url;

    attachment.file = {
      file_content_type: attachment.file_content_type,
      file_name: attachment.file_name,
      file_size: attachment.file_size,
      file_url: attachment.file_url,
    };

    let probativeAttachmentOptions = {};

    if (attachment.probative_attachment_status === 'available') {
      probativeAttachmentOptions = {
        download_url: attachment.probative_attachment_download_url,
        file_content_type: 'application/pdf',
        file_name: attachment.probative_attachment_file_name,
        file_size: attachment.probative_attachment_file_size,
        file_url: attachment.probative_attachment_file_url,
      };
    }

    attachment.probative_attachment = {
      ...probativeAttachmentOptions,
      status: attachment.probative_attachment_status,
    };

    delete attachment.file_content_type;
    delete attachment.file_name;
    delete attachment.file_size;
    delete attachment.file_url;
    delete attachment.probative_attachment_download_url;
    delete attachment.probative_attachment_file_content_type;
    delete attachment.probative_attachment_file_name;
    delete attachment.probative_attachment_file_size;
    delete attachment.probative_attachment_file_url;
    delete attachment.probative_attachment_status;
  },
});
