import Serializer from './application';

export default Serializer.extend({
  getHashForResource() {
    let [hash, addToIncludes] = Serializer.prototype.getHashForResource.apply(this, arguments);

    if (Array.isArray(hash)) {
      for (let child of hash) {
        this._adjust(child);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(label) {
    let { schema } = this.registry;

    if (label.label_list_id) {
      let labelList = schema.labelLists.find(label.label_list_id);
      label.organization_id = labelList.organizationId;
    }
  },
});
