export const CLAIM_STATUSES = {
  review: 'in_review',
  approved: 'approved',
  rejected: 'rejected',
};

export const CLAIM_TYPES = {
  atm: 'atm',
  commercial: 'commercial',
  fraud: 'fraud',
};
