export const APPROVAL_WORKFLOW_STEP_STATES = {
  APPROVED: 'approved',
  CURRENT: 'current',
  PENDING: 'pending',
  REJECTED: 'rejected',
};

export const APPROVAL_WORKFLOW_STEP_OPERATIONS = {
  ONE_OF: 'one_of',
  ALL_OF: 'all_of',
};
