import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  operator: 'before',
  field: 'due_date',
  offsetDays: 2,
  emailTitle: 'Email title',
  emailBody: 'Email body',

  afterCreate(reminderRule) {
    assert(
      reminderRule.organization,
      `Missing 'organization' relationship on 'reminderRule:${reminderRule.id}'`
    );

    assert(
      reminderRule.receivableInvoicesReminder,
      `Missing 'receivableInvoicesReminder' relationship on 'reminderRule:${reminderRule.id}'`
    );
  },
});
