import { association, Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  directDebitsAverageAmount: {
    value: 0,
    currency: 'EUR',
  },
  directDebitsTotalAmount: {
    value: 0,
    currency: 'EUR',
  },
  creditorIdentifier: i => `creditorIdentifier ${i + 1}`,
  creditorName: i => `creditorName ${i + 1}`,
  enrichmentData: () => {},
  firstDirectDebitDate: null,
  lastDirectDebitDate: null,
  lastDirectDebitDeclinedReason: null,
  mandateType: 'core',
  nextDirectDebitDate: null,
  recurrent: false,
  sequentialId: i => i + 1,
  status: 'active',
  successfulDirectDebitsCount: 0,
  umr: 'RUBE6989666666',
  mandatesCounterparty: association(),

  afterCreate(mandate, server) {
    assert(mandate.bankAccount, `Missing 'bankAccount' relationship on 'mandate:${mandate.id}'`);

    if (!mandate.slug) {
      let orgSlug = mandate.bankAccount.organization.slug;
      let slug = `${orgSlug}-mandate-${mandate.sequentialId}`;
      mandate.update({ slug });
    }

    if (!mandate.beneficiary) {
      mandate.update({ beneficiary: server.create('beneficiary') });
    }

    if (mandate.activityTag) {
      mandate.mandatesCounterparty.update({ activityTag: mandate.activityTag });
    }

    if (mandate.name) {
      mandate.creditorName = mandate.name;
    }

    assert(
      !mandate.organization && !mandate.organizationId,
      `You should not pass an 'organization' relationship on 'mandate:${mandate.id}'`
    );
  },
});
