import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  afterCreate(expenseReportsReceipt) {
    assert(
      expenseReportsReceipt.organization,
      `Missing 'organization' relationship on 'expense-reports-receipt:${expenseReportsReceipt.id}'`
    );
    assert(
      expenseReportsReceipt.attachment,
      `Missing 'attachment' relationship on 'expense-reports-receipt:${expenseReportsReceipt.id}'`
    );
  },
});
