import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);

    if (Array.isArray(json.bank_connections)) {
      json.connections = json.bank_connections;
      delete json.bank_connection;
    } else if (json.bankConnection) {
      json.connection = json.bank_connection;
      delete json.bankConnection;
    }

    return json;
  },
});
