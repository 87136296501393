import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  monthlyTransferLimitCents: null,
  perTransferLimitCents: null,
  currency: null,
  currentMonthsTransfersTotalAmount() {
    return this.monthlyTransferLimitCents;
  },

  afterCreate(model) {
    assert(model.membershipId, `Missing 'membership' relationship on 'spend-limit:${model.id}'`);
  },
});
