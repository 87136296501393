import { belongsTo, hasMany, Model } from 'miragejs';

export default Model.extend({
  source: belongsTo({ polymorphic: true }),
  target: belongsTo({ polymorphic: true }),
  paymentMethod: belongsTo({ polymorphic: true }),
  attachments: hasMany('table-attachment'),
  labels: hasMany(),
  receivableInvoices: hasMany(),
  supplierInvoices: hasMany(),
  initiator: belongsTo('membership'),
});
