import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  keyForModel: () => 'device',

  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);

    if (json.device) {
      this._adjust(json.device);
      return { device: json.device };
    } else if (json.devices) {
      for (let device of json.devices) {
        this._adjust(device);
      }
    }

    return json;
  },

  _adjust(device) {
    delete device.id;
  },
});
