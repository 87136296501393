import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

const NAMES = [
  'Enormous Bronze Lamp',
  'Mediocre Rubber Keyboard',
  'Gorgeous Granite Lamp',
  'Enormous Wooden Bag',
  'Practical Wool Watch',
  'Heavy Duty Marble Bench',
];

export default Factory.extend({
  name: i => NAMES[i % NAMES.length],
  createdAt: '2019-10-03T03:45:13Z',

  afterCreate(label) {
    assert(label.labelList, `Missing 'labelList' relationship on 'label:${label.id}'`);
  },
});
