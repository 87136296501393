import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';
import { differenceInCalendar } from '@qonto/mirage/utils/date';

import { EXERCISE_KIND_MONTHLY } from './budget';

export default Factory.extend({
  endDate: '2020-12-31',
  startDate: '2020-04-01',

  afterCreate(exercise, server) {
    assert(exercise.endDate, `Missing 'endDate' on 'exercise:${exercise.id}'`);
    assert(exercise.startDate, `Missing 'startDate' on 'exercise:${exercise.id}'`);

    let periods = [];
    let startDate = new Date(exercise.startDate);
    let year = startDate.getFullYear();
    let startMonth = startDate.getMonth();

    if (exercise.periods.length === 0) {
      if (exercise.budget.exerciseKind === EXERCISE_KIND_MONTHLY) {
        let numberOfMonths = differenceInCalendar(new Date(exercise.endDate), startDate, 'month');
        for (let i = 0; i <= numberOfMonths; i++) {
          periods.push(
            server.create('period', {
              amountBudgeted: {
                currency: 'EUR',
                value: '123',
              },
              endDate: new Date(Date.UTC(year, startMonth + i + 1, 0)).toISOString().split('T')[0],
              exercise,
              startDate: new Date(Date.UTC(year, startMonth + i, 1)).toISOString().split('T')[0],
            })
          );
        }
      } else {
        periods.push(
          server.create('period', {
            amountBudgeted: {
              currency: 'EUR',
              value: '123',
            },
            endDate: exercise.endDate,
            exercise,
            startDate: new Date(Date.UTC(year, startMonth, 1)).toISOString().split('T')[0],
          })
        );
      }
    }
    exercise.update({ periods });
  },
});
