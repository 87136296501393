import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class ReceivableInvoicesSettingsSerializer extends JSONAPISerializer {
  alwaysIncludeLinkageData = true;

  keyForAttribute(attr) {
    return underscore(attr);
  }

  serialize() {
    let { attributes, id } = super.serialize(...arguments).data;

    let { numbering_pattern: numberingPattern, next_number: nextNumber } = attributes;

    if (numberingPattern) {
      attributes.next_invoice_number =
        numberingPattern.replace(/\(YYYY\)|\(AAAA\)|\(JJJJ\)/g, new Date().getFullYear()) +
        nextNumber;
    }
    let year = new Date().getFullYear();
    attributes.credit_note_next_number_formatted = `${attributes.credit_note_numbering_pattern?.replace(
      /\(YYYY\)|\(AAAA\)|\(JJJJ\)/g,
      year
    )}${attributes.credit_note_next_number}`;
    attributes.invoice_next_number_formatted = `${attributes.invoice_numbering_pattern?.replace(
      /\(YYYY\)|\(AAAA\)|\(JJJJ\)/g,
      year
    )}${attributes.invoice_next_number}`;
    attributes.quote_next_number_formatted = `${attributes.quote_numbering_pattern?.replace(
      /\(YYYY\)|\(AAAA\)|\(JJJJ\)/g,
      year
    )}${attributes.quote_next_number}`;

    return {
      data: {
        id,
        type: 'settings',
        attributes,
      },
    };
  }
}
