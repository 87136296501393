import { pluralize } from 'ember-inflector';
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class RegisterRedirectionTokenSerializer extends JSONAPISerializer {
  shouldIncludeLinkageData(relationshipKey) {
    return relationshipKey !== 'user';
  }

  keyForAttribute(attr) {
    // use snake_case for attribute names
    return underscore(attr);
  }

  keyForRelationship(key) {
    // use snake_case for relationship names
    return underscore(key);
  }

  typeKeyForModel({ modelName }) {
    if (modelName === 'register-redirection-token') {
      modelName = 'redirection-token';
    }

    return underscore(pluralize(modelName));
  }
}
