import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  getHashForResource() {
    let [hash, addToIncludes] = ApplicationSerializer.prototype.getHashForResource.apply(
      this,
      arguments
    );

    if (Array.isArray(hash)) {
      for (let transfer of hash) {
        this._adjust(transfer);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(transfer) {
    let { schema } = this.registry;

    let bankAccount = schema.bankAccounts.find(transfer.bank_account_id);

    transfer.organization_id = bankAccount.organizationId;

    transfer.transfers = transfer.transfer_ids.map(transferId => {
      let transfer = schema.multiTransferItems.find(transferId);
      let [hash] = this.getHashForIncludedResource(transfer);
      return hash.multi_transfer_items[0];
    });

    delete transfer.transfer_ids;
  },
});
