import { Factory } from 'miragejs';

export default Factory.extend({
  name: i => `Bank ${i + 1}`,
  logo: {
    small: 'https://qonto.co/small.png',
    medium: 'https://qonto.co/medium.png',
  },
  popular: false,
  countries: ['FR, IT, ES, DE'],
});
