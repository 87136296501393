import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export const VALID_CODES = [
  'account_activity_general',
  'account_activity_transaction',
  'account_activity_reporting',
  'account_monthly_invoice_reporting',
  'account_activity_comments',
  'account_activity_matching',
  'card_activity_general',
  'card_activity_transaction',
  'request_transfer',
  'check',
  'budgets',
];

export default Factory.extend({
  devicesEmail: true,
  devicesPush: true,
  code: null,

  afterCreate(model, server) {
    assert(model.membership, `Missing 'membership' relationship on 'model:${model.id}'`);

    assert(VALID_CODES.includes(model.code), "You must pass a valid subscription 'code'");

    let subscriptions = server.db.subscriptions.where({
      membershipId: model.membershipId,
      code: model.code,
    });

    assert(
      subscriptions.length === 1,
      'You cannot create more than one subscription with same code per membership'
    );
  },
});
