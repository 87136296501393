import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

const NAMES = [
  'Enormous Bronze Lamp',
  'Mediocre Rubber Keyboard',
  'Gorgeous Granite Lamp',
  'Enormous Wooden Bag',
  'Practical Wool Watch',
  'Heavy Duty Marble Bench',
];

const COLORS = [
  '#d9a510',
  '#9fd907',
  '#698bd9',
  '#d9536b',
  '#f0ed00',
  '#cf88d9',
  '#faf4ff',
  '#60202c',
];

export default Factory.extend({
  name: i => NAMES[i % NAMES.length],
  color: i => COLORS[i % COLORS.length],
  rank: i => i,
  createdAt: '2019-10-03T03:45:13Z',

  afterCreate(label) {
    assert(label.organization, `Missing 'organization' relationship on 'label-list:${label.id}'`);
  },
});
