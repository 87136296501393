import Serializer from './application';

export default Serializer.extend({
  getHashForResource() {
    let [hash, addToIncludes] = Serializer.prototype.getHashForResource.apply(this, arguments);

    if (Array.isArray(hash)) {
      for (let transfer of hash) {
        this._adjust(transfer);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(transfer) {
    let { schema } = this.registry;

    let bankAccount = schema.bankAccounts.find(transfer.bank_account_id);
    transfer.organization_id = bankAccount.organizationId;
  },
});
