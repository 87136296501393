import { belongsTo, hasMany, Model } from 'miragejs';

export default Model.extend({
  approver: belongsTo('membership'),
  attachments: hasMany(),
  beneficiary: belongsTo(),
  bankAccount: belongsTo(),
  initiator: belongsTo('membership'),
  requestMultiTransfer: belongsTo(),
  approvalWorkflowState: belongsTo(),
});
