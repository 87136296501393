import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  legalName: i => `Frying Dutchman ${i}`,

  afterCreate(model) {
    assert(model.organization, `Missing 'organization' relationship on 'legal-entity:${model.id}'`);
  },
});
