import { belongsTo, hasMany, Model } from 'miragejs';

export default Model.extend({
  address: belongsTo(),
  documents: hasMany(),
  kycKybUpdateProcesses: hasMany(),
  memberships: hasMany(),
  treezorOrganization: belongsTo('organization'),
  eInvoiceActivation: belongsTo(),
  organizationKybDetails: belongsTo(),
});
