/* eslint-disable camelcase */
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class TableAttachmentSerializer extends JSONAPISerializer {
  alwaysIncludeLinkageData = false;
  include = ['probativeAttachment'];

  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(attr) {
    return underscore(attr);
  }

  typeKeyForModel({ modelName }) {
    if (modelName === 'table-attachment') {
      return 'attachment';
    }
    return underscore(modelName);
  }

  _attrsForModel(model) {
    let {
      attachment_type,
      download_url,
      created_at,
      file_content_type,
      file_name,
      file_size,
      file_url,
      thumbnail_url,
      slug,
    } = super._attrsForModel(model);

    return {
      file: {
        file_content_type,
        file_name,
        file_size,
        file_url,
      },
      attachment_type,
      download_url,
      thumbnail: {
        file_url: thumbnail_url,
      },
      created_at,
      slug,
    };
  }
}
