import ApplicationSerializer from './application';

export function adjustUser(user) {
  delete user.password;
  delete user.current_password;
  delete user.reset_password_token;
  delete user.sms_trigger_token;

  user.mfa_method = user.mfa_enabled ? user.mfa_method : null;

  user.avatar_thumb = { file_url: user.avatar_thumb_url };
  user.avatar = { file_url: user.avatar_url };
  user.default_avatar_thumb = { file_url: user.default_avatar_thumb_url };
  user.default_avatar = { file_url: user.default_avatar_url };

  delete user.avatar_thumb_url;
  delete user.avatar_url;
  delete user.default_avatar_thumb_url;
  delete user.default_avatar_url;
}

export default ApplicationSerializer.extend({
  serializeIds: 'included',
  include: [],

  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);

    adjustUser(json.user);

    return json;
  },
});
