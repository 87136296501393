import { Factory } from 'miragejs';

export default Factory.extend({
  /** @type string | null */
  birthdate: null,
  firstName: 'Some',
  /** @type 'male' | 'female' | null */
  gender: null,
  lastName: i => `Person ${i + 1}`,
  /** @type string | null */
  title: null,
});
