import { ActiveModelSerializer } from 'miragejs';

export default class extends ActiveModelSerializer {
  attrs = ['archived', 'exerciseKind', 'id', 'name', 'restricted', 'supervisorIds'];
  include = ['assignedTo', 'exercises'];
  embed = true;
  serializeIds = 'never';

  serialize(budget, request) {
    let json = super.serialize(...arguments);
    if (json.budgets) {
      json.budgets.forEach((budgetJson, index) => {
        this._adjust(budget.models[index], budgetJson, request);
      });
    } else if (json.budget) {
      this._adjust(budget, json.budget, request);
    }

    return json;
  }

  _adjust(budget, budgetJson, request) {
    this._adjustAssignedTo(budgetJson);
    this._adjustSupervisors(budget, budgetJson);
    if (request.queryParams.includes?.includes('active_period')) {
      this._addActivePeriod(budget, budgetJson);
    }
  }

  _adjustAssignedTo(budgetJson) {
    if (budgetJson.assigned_to) {
      budgetJson.assigned_to = { id: budgetJson.assigned_to.id, type: 'team' };
    }
  }

  _adjustSupervisors(budget, budgetJson) {
    if (budget.supervisors) {
      budgetJson.supervisor_ids = budget.supervisors.models.map(supervisor => supervisor.id);
    }
  }

  _addActivePeriod(budget, budgetJson) {
    let period = budget.exercises.models[0].periods.models[0];

    let amountSettled = period.transactions.models.reduce((sum, { amount }) => sum + amount, 0);

    budgetJson.active_period = {
      period_id: period.id,
      amount_settled: {
        currency: 'EUR',
        value: `${amountSettled}`,
      },
    };
  }
}
