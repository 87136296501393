import { Factory } from 'miragejs';

/**
 * https://openapi-master.staging.qonto-cbs.co/partner-register-client-api#tag/Organizations
 */
export default Factory.extend({
  legalName(i) {
    return `Organization ${i}`;
  },
});
