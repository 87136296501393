import { decamelize } from '@qonto/mirage/utils/string';

import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  keyForModel() {
    return 'stakeholder';
  },

  getHashForResource() {
    let [hash, addToIncludes] = ApplicationSerializer.prototype.getHashForResource.apply(
      this,
      arguments
    );

    if (Array.isArray(hash)) {
      for (let stakeholder of hash) {
        this._adjust(stakeholder);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(stakeholder) {
    let { schema } = this.registry;

    if (!stakeholder.user_id) {
      stakeholder.user_id = null;
    }

    if (!stakeholder.bank_account_id) {
      stakeholder.bank_account = {};
    } else {
      let bankAccount = schema.bankAccounts.find(stakeholder.bank_account_id);
      stakeholder.bank_account = this._transformKeys(bankAccount.attrs);
    }

    delete stakeholder.bank_account_id;

    if (stakeholder.address_id) {
      let addressResource = schema.addresses.find(stakeholder.address_id);
      let [addressesHash] = this.getHashForIncludedResource(addressResource);
      stakeholder.address = addressesHash.addresses[0];
    }

    // We remove all other properties of the address as the stakeholder
    // only requires the address.country attribute of the address.
    delete stakeholder.address_id;
    delete stakeholder.address.city;
    delete stakeholder.address.contact_name;
    delete stakeholder.address.first_line;
    delete stakeholder.address.second_line;
    delete stakeholder.address.third_line;
    delete stakeholder.address.zipcode;
  },

  _transformKeys(object) {
    let newObject = {};

    for (let key in object) {
      newObject[decamelize(key)] = object[key];
    }

    return newObject;
  },
});
