import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);

    // Remove the `id` and `v1` property from each serialized country to match the backend API response
    if (json.countries) {
      for (let country of json.countries) {
        delete country.id;
        delete country.v1;
      }
    }

    return json;
  },
});
