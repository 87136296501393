import { Factory } from 'miragejs';

export default Factory.extend({
  finishOn: '2020-03-08',
  recurrenceOn: '2020-01-08',
  voucherFeeType: null,
  voucherCode: 'yF5dtQ',
  voucherType: 'subscription',
  voucherAmount: 1,
  voucherMonthDuration: 1,
  voucherAmountCurrency: 'EUR',
  voucherRestrictions: () => [],
  discountValid: true,
});
