import { belongsTo, Model } from 'miragejs';

/**
 * This model is used by the `company` model and defines the `stakeholder`
 * models that will be created when a `register-organization` is derived from
 * the `company`.
 */
export default Model.extend({
  company: belongsTo(),
});
