import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  sequentialId: i => i + 1,
  activityTag: 'transport',
  amount: '0',
  amountCurrency: 'EUR',
  attachmentIds: [],
  bankAccountId: null,
  beneficiaryBic: '',
  beneficiaryEmail: '',
  beneficiaryIban: '',
  beneficiaryName: '',
  currency: null,
  createdAt: '2019-11-28T10:12:56.679Z',
  declinedNote: null,
  notifyByEmail: false,
  operationType: 'standing_monthly',
  processedAt: null,
  note: '',
  reference: null,
  requestTransfers: [],
  requestType: 'transfer',
  qontoBankAccount: false,
  status: 'pending',
  last_step: true,
  standingEndingDate: null,
  scheduledDate: null,

  afterCreate(request) {
    assert(
      request.bankAccount,
      `Missing 'bankAccount' relationship on 'request-transfer:${request.id}'`
    );

    if (!request.slug) {
      let orgSlug = request.bankAccount.organization && request.bankAccount.organization.slug;
      let slug = (orgSlug || '') + (orgSlug ? '-' : '') + `request-${request.sequentialId}`;
      request.update({ slug });
    }

    assert(
      !request.organization || !request.organizationId,
      `You should not pass an 'organization' relationship on 'request-transfer:${request.id}'`
    );
  },
});
