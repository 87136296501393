export const PRODUCT_PLAN_MONTHLY = {
  product_id: 'solo_plan_2024_monthly_id',
  product_kind: 'core',
  recurrence: 'monthly',
  code: 'solo_plan_2024',
};

export const PRODUCT_PLAN_ANNUAL = {
  product_id: 'solo_plan_2024_annual_id',
  product_kind: 'core',
  recurrence: 'annual',
  code: 'solo_plan_2024',
};

export const PRODUCT_ADDON_MONTHLY = {
  product_id: 'expense_management_2024_monthly_id',
  product_kind: 'addon',
  recurrence: 'monthly',
  code: 'expense_management_2024',
};

export const PRODUCT_ADDON_ANNUAL = {
  product_id: 'expense_management_2024_annual_id',
  product_kind: 'addon',
  recurrence: 'annual',
  code: 'expense_management_2024',
};
