import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  getHashForResource() {
    let [hash, addToIncludes] = ApplicationSerializer.prototype.getHashForResource.apply(
      this,
      arguments
    );

    if (Array.isArray(hash)) {
      for (let check of hash) {
        this._adjust(check);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(check) {
    let cmc7 = check.cmc7;

    check.cmc7 = {};
    check.cmc7.a = cmc7.substring(0, 7);
    check.cmc7.b = cmc7.substring(7, 19);
    check.cmc7.c = cmc7.substring(19, 31);

    check.amount_cents = Math.round(check.amount * 100);
    delete check.amount;

    let { schema } = this.registry;

    let bankAccount = schema.bankAccounts.find(check.bank_account_id);
    check.organization_id = bankAccount.organizationId;
  },
});
