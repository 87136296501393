import { Serializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class ReceivableInvoicesSharedBankAccountSerializer extends Serializer {
  attrs = ['bic', 'iban'];

  serializeIds = 'never';

  keyForAttribute(attr) {
    return underscore(attr);
  }
}
