import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  include(request) {
    let includes = [];
    let includesQP = request.queryParams.includes || [];

    if (includesQP.includes('memberships')) {
      includes.push('initiator', 'approver');
    }

    if (request.url.toString().includes('/upload')) {
      includes.push('transfers');
    }

    return includes;
  },

  keyForModel() {
    return 'request_multi_transfer';
  },

  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);
    if (this.request.url.toString().includes('/upload')) {
      json.request_multi_transfer.id = null;
      json.request_multi_transfer.transfers = json.request_multi_transfer_transfers;
      for (let transfer of json.request_multi_transfer.transfers) {
        transfer.id = null;
      }
      delete json.request_multi_transfer_transfers;
    }
    return json;
  },

  getHashForResource() {
    let [hash, addToIncludes] = ApplicationSerializer.prototype.getHashForResource.apply(
      this,
      arguments
    );

    if (Array.isArray(hash)) {
      for (let request of hash) {
        this._adjust(request);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(request) {
    let { schema } = this.registry;

    let bankAccount = schema.bankAccounts.find(request.bank_account_id);
    request.organization_id = bankAccount.organizationId;

    delete request.transfer_ids;
  },
});
