export const MIRAGE_SESSION_KEY = 'MIRAGE_SESSION';

export function destroySession() {
  window.localStorage.removeItem(MIRAGE_SESSION_KEY);
}

export function persistSession({ deviceId, deviceRemembered, expires, userId, websocketToken }) {
  window.localStorage.setItem(
    MIRAGE_SESSION_KEY,
    JSON.stringify({
      deviceId,
      deviceRemembered,
      expires,
      userId,
      websocketToken,
    })
  );
}

export function restoreSession() {
  let storageSession = window.localStorage.getItem(MIRAGE_SESSION_KEY);
  if (!storageSession) {
    return;
  }

  let mirageSession = JSON.parse(storageSession);
  if (Date.parse(mirageSession.expires) < Date.now()) {
    window.localStorage.removeItem(MIRAGE_SESSION_KEY);
    return;
  }

  return mirageSession;
}

export function getSession(schema) {
  let session = schema.mirageSessions.first() ?? restoreSession(schema);
  if (!session || Date.parse(session.expires) < Date.now()) {
    return {};
  }

  let user = schema.users.find(session.userId);
  return { session, user };
}

export function in30min() {
  return new Date(Date.now() + 30 * 60 * 1000);
}

export function generateWebsocketToken() {
  return Math.random().toString().slice(2);
}
