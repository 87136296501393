import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);
    let { method } = this.request;

    if (Array.isArray(json.invites)) {
      for (let invite of json.invites) {
        this._adjust(invite, { method });
      }
    } else {
      let includeLoginToken = method === 'GET' && json.invite.status === 'pending';
      this._adjust(json.invite, { includeLoginToken, method });
    }

    return json;
  },

  _adjust(invite, { includeLoginToken, method } = {}) {
    if (!includeLoginToken) {
      // Remove the `login_token` if the invite status is not `pending`
      delete invite.login_token;
    }

    if (method === 'POST') {
      delete invite.custom_permissions;
    }

    delete invite.status;

    let organization = this.schema.organizations.find(invite.organization_id);
    invite.organization_name = organization.name;
    invite.organization_legal_country = organization.legalCountry;
    invite.organization_avatar_thumb = { file_url: organization.avatarThumbUrl };
    invite.organization_avatar = { file_url: organization.avatarUrl };
    invite.organization_default_avatar_thumb = { file_url: organization.defaultAvatarThumbUrl };
    invite.organization_default_avatar = { file_url: organization.defaultAvatarUrl };
  },
});
