import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  docType: 'financial_statement',
  status: 'pending',

  afterCreate(model) {
    assert(
      model.documentCollectionProcess,
      `Missing 'document-collection-process' relationship on 'required-document:${model.id}'`
    );
  },
});
