/**
 * Qonto Logo - 32px
 */
export const QONTO_LOGO_32 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAk1BMVEUcHBwaGhr9/f3////7+/sYGBglJSX4+PgnJyfi4uLAwMAqKiogICCgn6CMjIxFRUUyMjK9vb0sLCzz8/Pw8PCKiYppaWlSUlIwMDD19fXu7e7q6eqtra2qqqqnp6eNjY17e3tzc3NPT0/Dw8PCwsKGhoZ4eHhtbW1OTk5KSkrHx8fExMS5ubllZWViYmJYWFhDQ0PQPVkIAAABHklEQVQ4y9VS2XKDMAxEhw/AXAlHQgKEnL3b//+62m1nMgZm+px9scdrSSutgkcC/PNgNlLA/U0ImRvvex5FQ/YXZY9siIqVl0NGhGGq4YdfVSFSJP2KKSKqPgbHdwoZ04mkLCRClUgITKIYKcxgojlVhFx+QPBeskU1ayvuXOBbHBfEyHtbbIpVskZsn553iNs+XxqVqV93ahxV+XKzUhZghD4emubQxADBItyHz+Z61AIWaVMXLV/HbXu+6SXeiiQqrUiibZLPzYv3jEhnLQtC1+bM3NTyuK6FqNfIrCoDfoGvkJAw0QHIhJlxNurKmdVt3D3vFSJZs2Z2X+Sv3fri7NZegk1xGjK4L0x6inKYrBwIf+WCqd8+bLYHwjegWw53l+b7bQAAAABJRU5ErkJggg==';
/**
 * Qonto Logo - 64px
 */
export const QONTO_LOGO_64 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAulBMVEUdHR3////8+/waGhoXFxciIiIfHx8VFRX6+vojIyMTExMPDw8lJSX39/cNDQ0wMDD19fXh4eErKyszMzPw8PDa2tp4eHgoKCjy8vLj4+PX19cICAjd3d2wsLA5OTnr6+vGxsZOTk7m5ubT09POzs63t7eNjY3Q0NCzs7OlpaWLi4t8fHxycnJra2tVVVVKSkrU1NTBwcG6urqSkpKHh4d+fn5CQkIAAACqqqqQkJCAgIBeXl6ZmZllZWWtdIPmAAADWElEQVRYw+3Uh27jMAwGYFIiJWtYXtk73de9e/P9X+ssOz0U1zrJA+QDAsSA9EuiZcLBwcFnpCRx/YMvsGYgMHEUdJKQsXW6Hia/SAcJ2mlVMXTSWVFc/LgDS58TmLWT67PTovABurApsDYZGOuhpuCDMLzqIwqcSOhk7CNG6XhgSbH/EKDdTYEieghbajB9agMEzkmSlv+SqZojNgHJZTDdAW6dYhyIIzyuph8KWE0SxF4MwFvi7hqAP8ZWmvczC0o2hdD1/CRvt3BO0I2Vu8pT0SbgTAWjQTK8ZZM0bVZHTH5axVuKQL4Qmy2McA5KAyhpv9dHqqeLev7MxFfchSTYQT04Ej2BvwiYAX6log7AZgs3GrbUQCtQeo4pYhyf4okGNmRO4nOSxPlzGwdtJaGfYD06Viy/eRtmdI0iSjHHfuZgF5aLQvR6GBNw5YegT5qAWNZxZQl28TJU/fYy1NaOFrhsA8TRq2aGXYbEtjzFtEnILwGuRBIDergqtQKzxxEAbLg+SvEoH+GF43msYJ010NR8X/vQ5k09NeeYKL5PUGDvJziG/bGHYG7PHsZLNRwv78/WJvDQetiX8iZ45ySX3kClgjXkWUvaP4ClG0ptqVxYs+AAUoIiBfuS0hiyfPv78Wi5yGbL1dMdUfAa9uccPK/ijR5nqo89xJPnMnAG+9JwPe5hjgJPaTrHUbxF4wHRnqtDKC8wxcZzgLN08//Ry732QDbrJ7hxZ+RrsnkazV6dhJ2GtlomuJlTKJZ21T6I+jotLMMuwRfYtp/a9dRL+5K23TDp5X1gBdtIBrrAXvsxplh40gTcj/MTEVO/OQ96a0ubXuE7gTcERFr9STC2mAgHzku15Qbp8vj9BdTLSQIGK+l7inmcXpfz3Gw7hJJu8N5LErwvoSLPxMDHog6IEF8Ca+jiCc7bBlpnjDMCkMBSgVkcxSYZf3jOcssOwlpgs/oIJ1UoYaOclieY5NhY2yF0Kd0lbnxzFqR8DzYA3wS2zoKHTu60Ld9s4Jwx8M6y0dPBpI04tQa6EBVYO35RjuB/1g8m8YbNyu4iyGw2e7i8JS0VfQpX4ODux6oYV647wAw5TDUwyE93nomUclNrMujeAbP2zW3XXw6KfQ2UlhYODg4++Qu/gy+hP0S9cAAAAABJRU5ErkJggg==';
/**
 * Default "C" image - 32px
 */
export const DEFAULT_C_IMAGE_32 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAclBMVEXVyPvUx/vUx/nWyf3XyvzSxfXQxPHYy/7Tx/fFueazqM+MhJ9QTVg2NDnNwfHKvuykm76bkrKQiKV8dZB1b4VybIJqZHdNSlZFQks+PETNwe7AteC9sdyupMionsKXjquBeZNwan9aVmVVUF5JRVA6OD8t2ROSAAAAhElEQVQ4y+2QRxLEMAgEQSBZwXJax83x/19cPoAOPrtPVNHAFHCwD4MIBRCYqaAgtfEWZI1qdMv6XZNV5+NvvIQh94xKgKl2RPCavbLC1afKILTBlAQA2xjtxPyWfPycvCLwPaez63Ji7RN+2JbPNjagCHL/2j+ilUKFuKJSH1CAgz38AXlDBKRIOKx7AAAAAElFTkSuQmCC';
/**
 * Default "C" image - 64px
 */
export const DEFAULT_C_IMAGE_64 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAtFBMVEXVyPvSxvjTxvnUx/ve0f/h1P/h0/8NDgfZzP3Xyf0YGBXg0v8oJykJCwPdz//GuugREgzbzv/ZzP/Xyv7Qw/XKvuy2q9SCe5ZZVWQ/PEQ2NDkcHBoPEAsFBgCto8upn8WWja6EfZhxa4I7OT8UFBDl1//NwO/BteG0qdCnncCgl7qbkrSNhaN+d5J2cIhrZXpkX3FgW21LR1NIRVBCQEkwLzMgIB/j1P+nnsKTiql3cYdUUF0gZottAAABKElEQVRYw+3Tx3bDIBAFUAYQIFC11aW4997T/v+/IsfJ8RoWXnF3LN5j5hxAlmVZ1stgACDIGLixUlRgbHg7YjhbrfohDbBRXqDhuOZ+5BULZdBAOumZc+/8XUq//qQGDe6J17uwE7u9ciI/tBvAyRu5mQqCCUvHzcXVXcBNKl44cL83cdYT2dccAWhfyiWFR9msioYMNDcY8mvaJeghnKeaOwAb+eUzI+Iu0i+4PEMARLtg61eJ+x8LqNBsALWe3LIY/52O5YCC5jsMveZrmvzGg4X0Bwx0RzhwL3uHFnKK6DrrEt2vMPeiqqeYctBeNjkDpAnT5Y3XRb7ZjSN+FEgf0Owk31rc2wpEkD4ciF6+P4wGIWvzJjAJnJYSgExhuCPIsizLeo0faecS/0PJ0U0AAAAASUVORK5CYII=';

export const NATURE_OF_OPERATIONS = [
  'invoicing',
  'book_keeping',
  'spend_management',
  'core_banking',
];

export const DE_FREELANCERS_LEGAL_CODES = [
  'DE002', // Einzelunternehmer
  'DE210', // Freiberufler
];

export const GBR_MANAGEMENT_TYPES = ['single', 'joint'];
