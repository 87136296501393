import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  // 2020-01, 2020-02, ...
  year: i => 2020 + Math.floor(i / 12),
  month: i => (i % 12) + 1,

  authorizedEmails: () => [],
  note: null,
  withAttachments: false,
  notifyByEmail: false,

  afterCreate(model) {
    assert(model.bankAccount, `Missing 'bankAccount' relationship on 'statement:${model.id}'`);

    if (!model.zipUrl) {
      model.update({
        zipUrl: `https://api-master.staging.qonto.co/v3/statements/${model.id}/download.zip`,
      });
    }
    if (!model.pdfUrl) {
      model.update({
        pdfUrl: `https://api-master.staging.qonto.co/v3/statements/${model.id}/download.pdf`,
      });
    }
    if (!model.slug) {
      let date = `${model.year}-${String(model.month).padStart(2, '0')}`;
      let orgSlug = model.bankAccount.organization.slug;
      let slug = `${date}-${orgSlug || ''}-statement`;
      model.update({ slug });
    }

    assert(
      !model.organization || !model.organizationId,
      `You should not pass an 'organization' relationship on 'statement:${model.id}'`
    );
  },
});
