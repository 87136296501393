import Serializer from './application';

export default Serializer.extend({
  getHashForResource() {
    let [hash, addToIncludes] = Serializer.prototype.getHashForResource.apply(this, arguments);

    if (Array.isArray(hash)) {
      for (let directDebitHold of hash) {
        this._adjust(directDebitHold);
      }
    } else {
      this._adjust(hash);
    }

    return [hash, addToIncludes];
  },

  _adjust(directDebitHold) {
    let { schema } = this.registry;

    let bankAccount = schema.bankAccounts.find(directDebitHold.bank_account_id);
    directDebitHold.organization_id = bankAccount.organizationId;
  },
});
