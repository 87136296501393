import Serializer from './application';

export default class PagopaPaymentSerializer extends Serializer {
  serialize() {
    let { pagopa_payment: payment } = super.serialize(...arguments);
    // We don't want to return the "bank_account_id" field
    // eslint-disable-next-line no-unused-vars
    let { bank_account_id: _, ...attr } = payment;
    return { pagopa_payment: attr };
  }
}
