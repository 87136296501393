import { Factory, trait } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

const FIRST_CREATION_DATE = '2019-07-06T12:45:13Z';
const MS_PER_DAY = 24 * 60 * 60 * 1000;
const IBANS = {
  FR: [
    'FR6512739000501363946291J78',
    'FR7616958000012262546240133',
    'FR7616958000015105065184394',
    'FR7616958000015952610051932',
  ],
  DE: [
    'DE76500105173443941244',
    'DE47100101239742807253',
    'DE64100101237725576323',
    'DE42100101231775881899',
  ],
  IT: [
    'IT98S0300203280923465561422',
    'IT73A3609201600065937636551',
    'IT05M3609201600339426537584',
    'IT39P3609201600056238350611',
  ],
  ES: [
    'ES0714655323357855744144',
    'ES5268880001603717115185',
    'ES4368880001615747523663',
    'ES8368880001628923068833',
  ],
  PT: [
    'FR6512739000501363946291J78',
    'FR7616958000012262546240133',
    'FR7616958000015105065184394',
    'FR7616958000015952610051932',
  ],
  AT: [
    'DE76500105173443941244',
    'DE47100101239742807253',
    'DE64100101237725576323',
    'DE42100101231775881899',
  ],
  BE: [
    'FR6512739000501363946291J78',
    'FR7616958000012262546240133',
    'FR7616958000015105065184394',
    'FR7616958000015952610051932',
  ],
  NL: [
    'FR6512739000501363946291J78',
    'FR7616958000012262546240133',
    'FR7616958000015105065184394',
    'FR7616958000015952610051932',
  ],
};

export default Factory.extend({
  coreBankingSystem: 'qonto',

  accountType: 'current',
  authorizedBalance: 0,
  balance: 0,
  balanceCurrency: 'EUR',
  bic: 'TRZOFR21XYZ',
  createdAt: i => generateCreationDate(i),
  // Provides a unique IBAN for the first 4 accounts.
  iban(i) {
    let legalCountry = this.organization?.legalCountry || 'FR';
    let IBANSLength = IBANS[legalCountry].length;
    return IBANS[legalCountry][i % IBANSLength];
  },
  ibanPdfUrl: 'https://qonto.co/iban.pdf',
  name: i => `Bank Account ${i + 1}`,
  processingBalance: 0,
  sequentialId: i => i + 1,
  status: 'active',
  main: null,

  // this is a temporary trait that should be used if the user has the
  // `employee` role, since mirage is currently not aware yet of user roles
  forEmployees: trait({
    balance: undefined,
    authorizedBalance: undefined,
    processingBalance: undefined,
    balanceCurrency: undefined,
    iban: undefined,
    ibanPdfUrl: undefined,
    bic: undefined,
  }),

  afterCreate(bankAccount, server) {
    assert(
      bankAccount.organization,
      `Missing 'organization' relationship on 'bank-account:${bankAccount.id}'`
    );

    if (!bankAccount.slug) {
      let slug = `${bankAccount.organization.slug}-bank-account-${bankAccount.sequentialId}`;
      bankAccount.update({ slug });
    }

    if (bankAccount.accountType === 'remunerated') {
      bankAccount.ibanPdfUrl = null;
    }

    // identifier is explicitely assigned in tests for v5 bank_accounts
    if (bankAccount.identifier) {
      if (!bankAccount.logo) {
        bankAccount.update({
          logo: {
            small: 'https://qonto.co/small.png',
            medium: 'https://qonto.co/medium.png',
          },
        });
      }

      if (!bankAccount.bankName) {
        if (bankAccount.coreBankingSystem === 'connect') {
          bankAccount.update({ bankName: 'ING' });
        } else {
          bankAccount.update({ bankName: 'Qonto' });
        }
      }
    }

    let existingBankAccounts = server.db.bankAccounts.where({
      organizationId: bankAccount.organizationId,
    });

    bankAccount.update({ main: existingBankAccounts.length === 1 });
  },
});

function generateCreationDate(i) {
  let timestamp = Date.parse(FIRST_CREATION_DATE) + i * MS_PER_DAY;
  let date = new Date(timestamp);
  return date.toISOString();
}
