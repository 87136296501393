import dayjs from 'dayjs';

export function differenceInCalendar(end, start, unit) {
  if (start === undefined || end === undefined) return NaN;
  let startOfUnit = unit;
  // This is required as startOf is locale dependent, the first day of this week according to ISO 8601 is Monday
  // We currently don't have usage of week in codebase but this line is added to prevent future flakiness
  if (unit === 'week') startOfUnit = 'isoWeek';
  return dayjs(end).startOf(startOfUnit).diff(dayjs(start).startOf(startOfUnit), unit);
}

export function differenceInBusinessDays(date1, date2) {
  let startDate = dayjs(date1).isAfter(date2) ? dayjs(date2) : dayjs(date1);
  let endDate = dayjs(date1).isAfter(date2) ? dayjs(date1) : dayjs(date2);

  let count = 0;
  while (startDate.isBefore(endDate, 'day')) {
    if (startDate.day() !== 0 && startDate.day() !== 6) {
      // Exclude Sundays (0) and Saturdays (6)
      count++;
    }
    startDate = startDate.add(1, 'day');
  }

  return count;
}

export function eachOfInterval(start, end, unit) {
  let startDate = dayjs(start);
  let endDate = dayjs(end);
  let array = [];

  let current = startDate.startOf(unit);

  while (current.isBefore(endDate) || current.isSame(endDate, unit)) {
    array.push(current.toDate());
    current = current.add(1, unit);
  }

  return array;
}

export function isWithinInterval(date, interval) {
  return dayjs(date).isBetween(interval.start, interval.end, 'day', '[]');
}

export function closestTo(baseDate, dates) {
  if (!baseDate || dates.length === 0) return null;

  return dates.reduce((closest, current) => {
    let currentDiff = Math.abs(dayjs(baseDate).diff(current));
    let closestDiff = Math.abs(dayjs(baseDate).diff(closest));
    return currentDiff < closestDiff ? current : closest;
  });
}

export function subBusinessDays(date, daysToSub) {
  let newDate = dayjs(date);
  let subDays = 0;

  while (subDays < daysToSub) {
    newDate = newDate.subtract(1, 'day');
    if (newDate.day() !== 0 && newDate.day() !== 6) {
      // Skip weekends
      subDays++;
    }
  }

  return newDate.toDate();
}
