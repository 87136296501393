import { association, Factory, trait } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

const STATUSES = ['unpaid', 'paid', 'cancelled', 'draft'];

export default Factory.extend({
  status: i => STATUSES[i % STATUSES.length],
  organization: association(),

  v4: trait({
    imported: i => i % 2 === 0,
    has_duplicates: i => i % 2 === 0,
  }),

  afterCreate(receivableInvoice, server) {
    assert(
      receivableInvoice.organization,
      `Missing 'organization' relationship on 'receivableInvoice:${receivableInvoice.id}'`
    );

    if (!receivableInvoice.customer) {
      receivableInvoice.update({
        customer: server.create('customer', { organization: receivableInvoice.organization }),
      });
    }

    if (!receivableInvoice.bankAccount) {
      receivableInvoice.update({
        bankAccount: server.create('bankAccount', { organization: receivableInvoice.organization }),
      });
    }

    if (!receivableInvoice.attachment && receivableInvoice.status !== 'draft') {
      receivableInvoice.update({
        attachment: server.create('attachment', {
          organization: receivableInvoice.organization,
          attachmentType: 'receivable_invoice',
        }),
      });
    }
  },
});
