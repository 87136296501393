import { pluralize } from 'ember-inflector';
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class UserActionSerializer extends JSONAPISerializer {
  alwaysIncludeLinkageData = true;

  typeKeyForModel({ modelName }) {
    return underscore(pluralize(modelName));
  }

  serialize() {
    let json = super.serialize(...arguments);

    if (Array.isArray(json.data)) {
      json.data.forEach(ua => {
        delete ua.id;
      });
    } else {
      delete json.data.id;
    }
    return json;
  }
}
