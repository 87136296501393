import { belongsTo, hasMany, Model } from 'miragejs';

export default Model.extend({
  organization: belongsTo(),
  customer: belongsTo(),
  bankAccount: belongsTo(),
  attachment: belongsTo(),
  receivableCreditNotes: hasMany(),
  quote: belongsTo(),
  invoiceSubscription: belongsTo('invoice-subscription', { inverse: null }),
  directDebitSubscription: belongsTo('direct-debit-subscription', { inverse: null }),
});
