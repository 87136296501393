import { Factory } from 'miragejs';

export default Factory.extend({
  amount: {
    value: 0,
    currency: 'EUR',
  },
  vatRate: 19,
  vatAmount: {
    value: 0,
    currency: 'EUR',
  },
  attachmentIds: [],
  iban: '',
  note: '',
  reference: 'reimbursement',
  status: 'pending',
  createdAt: '2019-11-28T10:12:56.679Z',
  organizationId: null,
  requestType: 'expense_report',
  lastStep: true,
});
