import { ActiveModelSerializer } from 'miragejs';

import { underscore } from '@qonto/mirage/utils/string';

export default class ReceivableCreditNotesSharedReceivableInvoicesSerializer extends ActiveModelSerializer {
  embed = true;
  serializeIds = 'never';

  keyForAttribute(attr) {
    return underscore(attr);
  }

  serializerFor(type) {
    return super.serializerFor(`receivable-credit-notes/shared/${type}`);
  }

  serialize() {
    let { receivable_invoice: attributes } = super.serialize(...arguments);
    let { id, number, customer_snapshot: customerSnapshot, issue_date: issueDate } = attributes;

    let originalInvoice = {};
    originalInvoice.id = id;
    originalInvoice.number = number;
    originalInvoice.customer_snapshot = customerSnapshot;
    originalInvoice.issue_date = issueDate;

    return originalInvoice;
  }
}
