export default [
  {
    locale: 'EN',
    email_title: 'An invoice is due soon',
    email_body:
      'Hello, the following invoice is due soon. If it has already been paid, you can ignore this email.',
    operator: 'before',
    field: 'due_date',
  },
  {
    locale: 'EN',
    email_title: 'An invoice is due today',
    email_body:
      'Hello, the following invoice is due today. If it has already been paid, you can ignore this email.',
    operator: 'on',
    field: 'due_date',
  },
  {
    locale: 'EN',
    email_title: 'An invoice is overdue',
    email_body:
      'Hello, the following invoice is overdue. If it has already been paid, you can ignore this email.',
    operator: 'after',
    field: 'due_date',
  },
  {
    locale: 'FR',
    email_title: 'Une facture est bientôt due',
    email_body:
      'Bonjour, la facture suivante est bientôt due. Si elle a déjà été payée, vous pouvez ignorer cet email.',
    operator: 'before',
    field: 'due_date',
  },
  {
    locale: 'FR',
    email_title: "Une facture est due aujourd'hui",
    email_body:
      "Bonjour, la facture suivante est due aujourd'hui. Si elle a déjà été payée, vous pouvez ignorer cet email.",
    operator: 'on',
    field: 'due_date',
  },
  {
    locale: 'FR',
    email_title: 'Une facture est en retard',
    email_body:
      'Bonjour, la facture suivante est en retard. Si elle a déjà été payée, vous pouvez ignorer cet email.',
    operator: 'after',
    field: 'due_date',
  },
  {
    locale: 'ES',
    email_title: 'Una factura vencerá pronto',
    email_body:
      'Hola, la siguiente factura vencerá pronto. Si ya ha sido pagada, puede ignorar este correo electrónico.',
    operator: 'before',
    field: 'due_date',
  },
  {
    locale: 'ES',
    email_title: 'Una factura vence hoy',
    email_body:
      'Hola, la siguiente factura vence hoy. Si ya ha sido pagada, puede ignorar este correo electrónico.',
    operator: 'on',
    field: 'due_date',
  },
  {
    locale: 'ES',
    email_title: 'Una factura está vencida',
    email_body:
      'Hola, la siguiente factura está vencida. Si ya ha sido pagada, puede ignorar este correo electrónico.',
    operator: 'after',
    field: 'due_date',
  },
  {
    locale: 'DE',
    email_title: 'Eine Rechnung ist bald fällig',
    email_body:
      'Hallo, die folgende Rechnung ist bald fällig. Wenn sie bereits bezahlt wurde, können Sie diese E-Mail ignorieren.',
    operator: 'before',
    field: 'due_date',
  },
  {
    locale: 'DE',
    email_title: 'Eine Rechnung ist heute fällig',
    email_body:
      'Hallo, die folgende Rechnung ist heute fällig. Wenn sie bereits bezahlt wurde, können Sie diese E-Mail ignorieren.',
    operator: 'on',
    field: 'due_date',
  },
  {
    locale: 'DE',
    email_title: 'Eine Rechnung ist überfällig',
    email_body:
      'Hallo, die folgende Rechnung ist überfällig. Wenn sie bereits bezahlt wurde, können Sie diese E-Mail ignorieren.',
    operator: 'after',
    field: 'due_date',
  },
  {
    locale: 'IT',
    email_title: 'Una fattura scadrà presto',
    email_body:
      'Ciao, la seguente fattura scadrà presto. Se è già stata pagata, puoi ignorare questa email.',
    operator: 'before',
    field: 'due_date',
  },
  {
    locale: 'IT',
    email_title: 'Una fattura scade oggi',
    email_body:
      'Ciao, la seguente fattura scade oggi. Se è già stata pagata, puoi ignorare questa email.',
    operator: 'on',
    field: 'due_date',
  },
  {
    locale: 'IT',
    email_title: 'Una fattura è scaduta',
    email_body:
      'Ciao, la seguente fattura è scaduta. Se è già stata pagata, puoi ignorare questa email.',
    operator: 'after',
    field: 'due_date',
  },
];
