import CompanyCreationSerializer from './-company-creation';

export default CompanyCreationSerializer.extend({
  getResourceObjectForModel() {
    let hash = CompanyCreationSerializer.prototype.getResourceObjectForModel.apply(this, arguments);

    hash.attributes.url = `https://bucket.s3-aws-region.amazonaws.com/files/${hash.id}/${hash.attributes.filename}`;

    return hash;
  },
});
