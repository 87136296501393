import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  status: 'pending',

  afterCreate(solutionInstance) {
    assert(
      solutionInstance.organization,
      `Missing 'organization' relationship on 'solution-instance:${solutionInstance.id}'`
    );
  },
});
