import PartnerSerializer from './-partner';

export default class PartnerStakeholderSerializer extends PartnerSerializer {
  shouldIncludeLinkageData(relationshipKey) {
    /**
     * Taken from this list :
     * https://gitlab.qonto.co/back/partner-register/-/blob/master/app/serializers/client_api/stakeholder_serializer.rb
     **/
    return ['organization', 'kycSdkToken', 'registration'].includes(relationshipKey);
  }
}
