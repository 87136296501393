import { Factory } from 'miragejs';

import { assert } from '@qonto/mirage/utils/assert';

export default Factory.extend({
  name: '',
  status: 'scheduled',
  statusGroup: 'upcoming',
  createdAt: '2021-10-03T03:45:13Z',
  updatedAt: '2021-10-05T08:15:53Z',
  currency: 'EUR',
  declinedReason: 'other',
  documentAmount: 865431,
  documentAmountCents: 86543100,
  expirationDate: '2021-11-23',
  isCancelable: true,
  canceledAt: null,
  payer: {
    address: {
      province: null,
      street_address: '41015 Alvaro Row 479 Suite 691',
    },
    birth_date: '1974-02-26T00:00:00.000Z',
    birth_place: 'Accadia',
    birth_providence: null,
    business_name: 'Wilkinson-Klein26',
    first_name: 'John',
    fiscal_code: 'MLLSNT82P65Z404U',
    is_different_year: true,
    last_name: 'Doe',
    other_related_fiscal_code: null,
    sex: 'M',
  },
  payerAgent: {
    fiscal_code: '1337',
    type: 'IT',
  },
  taxes: {
    erario: {
      deed_code: 'deed_code_1',
      office_code: 'office_code_1',
      tax_list: [
        {
          reference_month: '0004',
          tax_amount_cents: 67549884,
          reference_year: '2021',
          tax_code: '1234',
        },
        {
          tax_amount_cents: -96940280,
          reference_month: '0005',
          compensation_amount_cents: 63753301,
          reference_year: '2021',
          tax_code: 'E',
        },
      ],
    },
    imu: {
      tax_list: [
        {
          is_real_estate_variation: true,
          reference_month: '0004',
          compensation_amount_cents: 1000,
          real_estate_count: 10,
          is_amendment: true,
          tax_amount_cents: 100000,
          receiver_code: '12234',
          tax_code: 'voluptate pariatur nostrud',
          is_final_payment: true,
          reference_year: '2021',
          is_deposit: true,
        },
        {
          reference_month: '0004',
          is_final_payment: true,
          reference_year: '2021',
          tax_code: 'occaecat',
        },
        {
          reference_month: '0005',
          reference_year: '2021',
          tax_code: 'ad',
        },
        {
          is_real_estate_variation: false,
          reference_month: '0004',
          tax_amount_cents: 50495799,
          reference_year: '2021',
          tax_code: 'dolore ut minim ullamco laboris',
          is_final_payment: true,
          receiver_code: 'ut deserunt qui velit',
        },
        {
          reference_month: '0002',
          receiver_code: 'qu',
          reference_year: '2021',
          tax_code: 'in nostrud',
        },
      ],
      deduction_amount_cents: -40303375,
      operation_code: 'reprehenderit nulla proident',
    },
    inps: {
      tax_list: [
        {
          compensation_amount_cents: 60242200,
          reference_period_from: '4725-06-10',
          tax_amount_cents: 5543019,
          branch_code: 'amet cillum ad nisi',
          reference_period_to: '2218-10-27',
          inps_code: 'eiusmod Excepteur fugiat Duis',
        },
      ],
    },
    other_institutions: {
      inail: {
        tax_list: [
          {
            check_code: 'check_code1',
            tax_type: 'tax_type1',
            compensation_amount_cents: 10000,
            tax_amount_cents: 100000,
            company_code: 'company_code1',
            branch_code: 'branch1',
            reference_number: 'reference_number1',
          },
        ],
      },
    },
    regioni: {
      tax_list: [
        {
          reference_month: '0004',
          reference_year: '2021',
          tax_code: '1234',
        },
        {
          reference_month: '0005',
          reference_year: '2021',
          tax_code: '12345',
        },
        {
          reference_month: '0003',
          reference_year: '2021',
          tax_code: '123456',
        },
      ],
    },
  },

  afterCreate(f24Order) {
    assert(
      f24Order.organization,
      `Missing 'organization' relationship on 'f24-order:${f24Order.id}'`
    );
    assert(
      f24Order.bankAccount,
      `Missing 'bankAccount' relationship on 'f24-order:${f24Order.id}'`
    );
    assert(f24Order.membership, `Missing 'membership' relationship on 'f24-order:${f24Order.id}'`);
  },
});
